
    module.exports = {
      functional: true,
      render(_h, _vm) {
        const { _c, _v, data, children = [] } = _vm;

        const {
          class: classNames,
          staticClass,
          style,
          staticStyle,
          attrs = {},
          ...rest
        } = data;

        return _c(
          'svg',
          {
            class: [classNames,staticClass],
            style: [style,staticStyle],
            attrs: Object.assign({"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 1000 1000"}, attrs),
            ...rest,
          },
          children.concat([_c('path',{attrs:{"d":"M0 500q6-49 64-110 79-80 176-129 129-60 260-60 137 2 260 60 103 53 176 129 64 73 64 110-6 49-64 109-79 80-176 129-129 61-260 61-137-2-260-61-103-53-176-129Q0 537 0 500zm264 0q0 94 69 159t167 65 167-65 69-159-69-159-167-66-167 66-69 159zm86-1q0-60 44-102t106-42 106 42 44 102-44 102-106 43-106-43-44-102z"}})])
        )
      }
    }
  