
    module.exports = {
      functional: true,
      render(_h, _vm) {
        const { _c, _v, data, children = [] } = _vm;

        const {
          class: classNames,
          staticClass,
          style,
          staticStyle,
          attrs = {},
          ...rest
        } = data;

        return _c(
          'svg',
          {
            class: ["shopping-cart-solid_svg__svg-inline--fa shopping-cart-solid_svg__fa-shopping-cart shopping-cart-solid_svg__fa-w-18",classNames,staticClass],
            style: [style,staticStyle],
            attrs: Object.assign({"aria-hidden":"true","data-prefix":"fas","data-icon":"shopping-cart","xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 576 512"}, attrs),
            ...rest,
          },
          children.concat([_c('path',{attrs:{"fill":"currentColor","d":"m528.12 301.319 47.273-208C578.806 78.301 567.391 64 551.99 64H159.208l-9.166-44.81C147.758 8.021 137.93 0 126.529 0H24C10.745 0 0 10.745 0 24v16c0 13.255 10.745 24 24 24h69.883l70.248 343.435C147.325 417.1 136 435.222 136 456c0 30.928 25.072 56 56 56s56-25.072 56-56c0-15.674-6.447-29.835-16.824-40h209.647C430.447 426.165 424 440.326 424 456c0 30.928 25.072 56 56 56s56-25.072 56-56c0-22.172-12.888-41.332-31.579-50.405l5.517-24.276c3.413-15.018-8.002-29.319-23.403-29.319H218.117l-6.545-32h293.145c11.206 0 20.92-7.754 23.403-18.681z"}})])
        )
      }
    }
  