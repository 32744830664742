import AsyncFactory from '../async';

export default
[
  {
    path: '',
    name: 'bulkDomainLabels',
    component: AsyncFactory(() => import(/* webpackChunkName: "Bulk" */ '../views/domains/bulk/BulkLabels.vue')),
    meta:
      {
        title: 'domains.bulk.title',
        localize: true,
        menu: true,
      },
  },
];
