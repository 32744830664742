<template>
  <div v-if="canShowUserSnap" class="user_feedback" @click="showUserSnap">{{ $t('buttons.feedback') }}</div>
</template>

<script>
export default
{
  name: 'UserFeedback',
  computed:
    {
      canShowUserSnap()
      {
        return this.$root.userSnapLoaded && ((this.$root.whiteLabel || {}).globals || {}).enableFeedback;
      },
    },
  methods:
    {
      showUserSnap()
      {
        // must be configured in the UserSnap project: Audience = nobody, trigger = AutoPopup (API event), event = show_feedback (name does not matter)
        if (window.Usersnap)
        {
          console.time('userSnap');
          window.Usersnap.show(this.$root.cfg.userSnapKey).then((widgetApi) => widgetApi.open());
        }
        else
        {
          this.$root.showFailed(this.$t('usersnap_init'));
        }
      }
    }
};
</script>

<style lang="scss">
  .user_feedback
  {
    position: fixed;
    top: 50%;
    left: 0;
    margin: 0;
    padding: 2px 10px;
    background-color: #AAA;
    color: #FFF;
    font-size: 14px;
    text-align: center;
    border: 1px solid #666;
    border-top: none;
    cursor: pointer;
    z-index: 11;
    transform: rotate(270deg) translateY(50%);
    transform-origin: left;
  }
</style>
