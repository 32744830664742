
    module.exports = {
      functional: true,
      render(_h, _vm) {
        const { _c, _v, data, children = [] } = _vm;

        const {
          class: classNames,
          staticClass,
          style,
          staticStyle,
          attrs = {},
          ...rest
        } = data;

        return _c(
          'svg',
          {
            class: [classNames,staticClass],
            style: [style,staticStyle],
            attrs: Object.assign({"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 1070 1000"}, attrs),
            ...rest,
          },
          children.concat([_c('path',{attrs:{"d":"M1070 938q0 21-2 63H1v-31q0-21-1-32 0-30 1-37 12-49 64-85t112-53 125-47 97-65q17-22 17-38 0-22-11-73-4-21-10-37t-16-33-16-31q-15-35-33-132-6-38-6-75 0-105 54-168T535 1t158 63 53 168q0 31-7 75-14 89-32 132-6 14-15 31t-16 33-11 37q-11 51-11 73 0 18 17 38 31 36 97 65t125 47 111 53 64 85q2 8 2 37z"}})])
        )
      }
    }
  