// https://javascriptweblog.wordpress.com/2011/08/08/fixing-the-javascript-typeof-operator/
Object.toType = (function toType(global)
{
  return function getType(obj)
  {
    if (obj === global)
    {
      return 'global';
    }
    // global, array, regexp, math, json, object, date, number, string, boolean, function, arguments
    return ({}).toString.call(obj).match(/\s([a-z|A-Z]+)/)[1].toLowerCase();
  };
})(this);

export function uuid(a)
{
  return a
    ? (a ^ Math.random() * 16 >> a / 4).toString(16)
    : ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, uuid);
}

export function isDate(d)
{
  return (!!d) && Object.prototype.toString.call(d) === '[object Date]';
}

export function isArray(a)
{
  return (!!a) && Object.prototype.toString.call(a) === '[object Array]';
}

export function isObject(a)
{
  return (!!a) && a.constructor === Object;
}

export function strReverse(str)
{
  return Array.from(str).reverse().join('');
}

export function strCompare(a, b)
{
  if (a === null) return 1;
  else if (b === null) return -1;
  if (typeof a === 'number' && typeof b === 'number') return a - b;
  if (isDate(a) || isDate(b))
  {
    const now = new Date();
    return (a || now).getTime() - (b || now).getTime();
  }
  // if (a.match(/^[+-]?[0-9]+(\.[0-9]+)?$/) && b.match(/^[+-]?[0-9]+(\.[0-9]+)?$/)) return a - b;
  if (a < b) return -1;
  else if (a > b) return 1;
  else return 0;
}

export function arrayUnique(arr)
{
  if (!isArray(arr)) return [];
  return arr.filter((value, index, self) => self.indexOf(value) === index);
}

export function arrayMax(arr)
{
  if (!isArray(arr)) return 0;
  let res = arr[0];
  for (let i = 1; i < arr.length; i++) if (arr[i] > res) res = arr[i];
  return res;
}

export function arrayMin(arr)
{
  if (!isArray(arr)) return 0;
  let res = arr[0];
  for (let i = 1; i < arr.length; i++) if (arr[i] < res) res = arr[i];
  return res;
}

export function arrayMap(arr, field = 'id')
{
  const result = {};
  const path = field.split('.');
  arr.forEach(item =>
  {
    if (path.length === 1) result[item[field]] = item;
    else
    {
      let obj = item;
      for (let i = 0; i < path.length; i++)
      {
        obj = obj[path[i]];
      }
      result[obj] = item;
    }
  });
  return result;
}

export function arrChunk(arr, size)
{
  return arr.reduce((acc, e, i) =>
  {
    if (i % size)
    {
      acc[acc.length - 1].push(e);
    }
    else
    {
      acc.push([e]);
    }
    return acc;
  }, []);
}

export function strSplit(str, chunkSize = 1)
{
  if (!str || chunkSize < 1) return [];
  const chunks = [];
  let pos = 0;
  const len = str.length;
  while (pos < len) chunks.push(str.slice(pos, pos += chunkSize));
  return chunks;
}

export function thousand(value, separator = ' ')
{
  return String(value).replace(/([^-])(?=(\d{3})+(\.\d\d)?$)/g, '$1' + separator);
}

export function xorColor(color)
{
  color = String(color);
  if (color[0] === '#') color = color.substr(1);
  if (color.length === 3) color = color[0] + color[0] + color[1] + color[1] + color[2] + color[2];
  if (color.length !== 6) return '000000';
  const r = parseInt(color.substr(0, 2), 16);
  const g = parseInt(color.substr(2, 2), 16);
  const b = parseInt(color.substr(4, 2), 16);
  return (0.213 * r + 0.715 * g + 0.072 * b < 165) ? '#FFFFFF' : '#000000';
}

// difference in whole days of d2 - d1
export function dateDiff(d1, d2)
{
  const oneDay = 1000 * 60 * 60 * 24;
  const current = new Date();
  const diff = (d2 || current).getTime() - (d1 || current).getTime();
  return Math.round(diff / oneDay);
}

export function equalObject(a, b)
{
  let result = true;
  for (const key in a)
  {
    if (!(key in b) || a[key] != b[key])
    {
      result = false;
      break;
    }
  }
  return result;
}

export function sameObject(a, b)
{
  let same = true;
  for (const key in a)
  {
    if (a[key] != b[key])
    {
      same = false;
      break;
    }
  }
  // to handle the case when a KEY exists in B but not in A
  for (const key in b)
  {
    if (a[key] != b[key])
    {
      same = false;
      break;
    }
  }
  return same;
}

export function nl2br(msg)
{
  return (msg || '').replace(/[\r]/g, '').replace(/[\n]/g, '<br>');
}

export function addTrailingDot(value)
{
  return (typeof value == 'string') ? value.replace(/\.?$/, '.') : value;
}

export function isNewerVersion(_old, _new)
{
  // return true if SemVersion A is newer than B
  const oldVer = _old.split('.');
  const newVer = _new.split('.');
  if (+oldVer[0] < +newVer[0]) return false;
  if (+oldVer[0] > +newVer[0]) return true;
  if (+oldVer[1] < +newVer[1]) return false;
  if (+oldVer[1] > +newVer[1]) return true;
  return +oldVer[2] > +newVer[2];
}

export function clone(obj)
{
  let copy;

  // Handle the 3 simple types, and null or undefined
  if (obj == null || typeof obj != 'object') return obj;

  // Handle Date
  if (obj instanceof Date)
  {
    copy = new Date();
    copy.setTime(obj.getTime());
    return copy;
  }

  // Handle Array
  if (obj instanceof Array)
  {
    copy = [];
    for (let i = 0, len = obj.length; i < len; i++)
    {
      copy[i] = clone(obj[i]);
    }
    return copy;
  }

  // Handle Object
  if (obj instanceof Object)
  {
    copy = {};
    for (const attr in obj)
    {
      /* eslint-disable-next-line no-prototype-builtins */
      if (obj.hasOwnProperty(attr)) copy[attr] = clone(obj[attr]);
    }
    return copy;
  }

  throw new Error("Unable to copy obj! Its type isn't supported.");
}

export function dateText(dateObj)
{
  return dateObj ? dateObj.getFullYear() + '-' + String(dateObj.getMonth() + 1).padStart(2, '0') + '-' + String(dateObj.getDate()).padStart(2, '0') : undefined;
}

export function stringDate(text)
{
  const arr = (text || '').split('-');
  const tmp = new Date(arr[0], arr[1] - 1, arr[2]);
  return isNaN(tmp.getTime()) ? undefined : tmp;
}

// we have to clear the INPUTs of type FILE after each selection - otherwise if we try to select the same file twice, the CHANGE event will not fire on the 2nd time
export function clearFileInput(ctrl)
{
  if (!ctrl) return;
  try
  {
    ctrl.value = null;
  }
  catch (ex)
  {}
  if (ctrl.value) ctrl.parentNode.replaceChild(ctrl.cloneNode(true), ctrl);
}

export function escapeRegExp(text)
{
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

export function passwordScore(pass)
{
  let score = 0;
  const password = (pass || '').trim();
  if (password.length >= 8) score++;
  if (/[A-Z]/.test(password)) score++;
  if (/[a-z]/.test(password)) score++;
  if (/[!-@^_{}~]/.test(password)) score++;
  return score;
  /*
  const matches = {
    pos: {},
    neg: {}
  };
  const counts = {
    pos: {},
    neg: {
      seqLetter: 0,
      seqNumber: 0,
      seqSymbol: 0
    }
  };
  let tmp, back, forth, i;
  let strength = 0;
  const letters = 'abcdefghijklmnopqrstuvwxyz';
  const numbers = '01234567890';
  const symbols = '\\/!@#$%&(){}=?+-.,;_~*<>|';

  if (pass)
  {
    // Benefits
    matches.pos.lower = pass.match(/[a-z]/g);
    matches.pos.upper = pass.match(/[A-Z]/g);
    matches.pos.numbers = pass.match(/\d/g);
    matches.pos.symbols = pass.match(/[$-/:-?{-~!^_`[\]]/g);
    matches.pos.middleNumber = pass.slice(1, -1).match(/\d/g);
    matches.pos.middleSymbol = pass.slice(1, -1).match(/[$-/:-?{-~!^_`[\]]/g);

    counts.pos.lower = matches.pos.lower ? matches.pos.lower.length : 0;
    counts.pos.upper = matches.pos.upper ? matches.pos.upper.length : 0;
    counts.pos.numbers = matches.pos.numbers ? matches.pos.numbers.length : 0;
    counts.pos.symbols = matches.pos.symbols ? matches.pos.symbols.length : 0;

    tmp = 0;
    for (const key in counts.pos)
    {
      // if has count will add 1
      tmp += Math.min(1, counts.pos[key]);
    }

    counts.pos.numChars = pass.length;
    tmp += (counts.pos.numChars >= 8) ? 1 : 0;

    counts.pos.requirements = (tmp >= 3) ? tmp : 0;
    counts.pos.middleNumber = matches.pos.middleNumber ? matches.pos.middleNumber.length : 0;
    counts.pos.middleSymbol = matches.pos.middleSymbol ? matches.pos.middleSymbol.length : 0;

    // Deductions
    matches.neg.consecLower = pass.match(/(?=([a-z]{2}))/g);
    matches.neg.consecUpper = pass.match(/(?=([A-Z]{2}))/g);
    matches.neg.consecNumbers = pass.match(/(?=(\d{2}))/g);
    matches.neg.onlyNumbers = pass.match(/^[0-9]*$/g);
    matches.neg.onlyLetters = pass.match(/^([a-z]|[A-Z])*$/g);

    counts.neg.consecLower = matches.neg.consecLower ? matches.neg.consecLower.length : 0;
    counts.neg.consecUpper = matches.neg.consecUpper ? matches.neg.consecUpper.length : 0;
    counts.neg.consecNumbers = matches.neg.consecNumbers ? matches.neg.consecNumbers.length : 0;

    // sequential letters (back and forth)
    for (i = 0; i < letters.length - 2; i++)
    {
      const p2 = pass.toLowerCase();
      forth = letters.substring(i, parseInt(i + 3));
      back = strReverse(forth);
      if (p2.indexOf(forth) !== -1 || p2.indexOf(back) !== -1)
      {
        counts.neg.seqLetter++;
      }
    }

    // sequential numbers (back and forth)
    for (i = 0; i < numbers.length - 2; i++)
    {
      forth = numbers.substring(i, parseInt(i + 3));
      back = strReverse(forth);
      if (pass.indexOf(forth) !== -1 || pass.toLowerCase().indexOf(back) !== -1)
      {
        counts.neg.seqNumber++;
      }
    }

    // sequential symbols (back and forth)
    for (i = 0; i < symbols.length - 2; i++)
    {
      forth = symbols.substring(i, parseInt(i + 3));
      back = strReverse(forth);
      if (pass.indexOf(forth) !== -1 || pass.toLowerCase().indexOf(back) !== -1)
      {
        counts.neg.seqSymbol++;
      }
    }

    // repeated chars
    const repeatedCounts = {};
    Array.from(pass.toLowerCase()).forEach(symbol =>
    {
      if (!repeatedCounts[symbol]) repeatedCounts[symbol] = 0;
      repeatedCounts[symbol]++;
    });
    let numRepeatedSymbols = 0;
    for (const key in repeatedCounts)
    {
      if (repeatedCounts[key] > 1) numRepeatedSymbols++;
    }
    counts.neg.repeated = numRepeatedSymbols;

    // Calculations
    strength += counts.pos.numChars * 4;
    if (counts.pos.upper)
    {
      strength += (counts.pos.numChars - counts.pos.upper) * 2;
    }
    if (counts.pos.lower)
    {
      strength += (counts.pos.numChars - counts.pos.lower) * 2;
    }
    if (counts.pos.upper || counts.pos.lower)
    {
      strength += counts.pos.numbers * 4;
    }
    strength += counts.pos.symbols * 6;
    strength += (counts.pos.middleSymbol + counts.pos.middleNumber) * 2;
    strength += counts.pos.requirements * 2;

    strength -= counts.neg.consecLower * 2;
    strength -= counts.neg.consecUpper * 2;
    strength -= counts.neg.consecNumbers * 2;
    strength -= counts.neg.seqNumber * 3;
    strength -= counts.neg.seqLetter * 3;
    strength -= counts.neg.seqSymbol * 3;

    if (matches.neg.onlyNumbers)
    {
      strength -= counts.pos.numChars;
    }
    if (matches.neg.onlyLetters)
    {
      strength -= counts.pos.numChars;
    }
    if (counts.neg.repeated)
    {
      strength -= (counts.neg.repeated / counts.pos.numChars) * 10;
    }
  }

  return Math.max(0, Math.min(100, Math.round(strength)));
  */
}

export function transformTLDrules(rules)
{
  return {
    status: +rules.status,
    agreement: (rules.agreement || [])[0],
    autorenew: (rules.autorenew || [])[0] > 0,
    countrycode: (rules.countrycode || [])[0],
    countryconnection: (rules.countryconnection || [])[0] > 0,
    dnssec: (rules.dnssec || [])[0] > 0,
    idn: (rules.idn || [])[0] > 0,
    maxLen: +(rules.maxLen || [])[0],
    maxNS: +(rules.maxNS || [])[0],
    minLen: +(rules.minLen || [])[0],
    minNS: +(rules.minNS || [])[0],
    nameSimilarity: (rules.namesimilarity || [])[0] === 'yes',
    registry_admin_email: (rules.registry_admin_email || [])[0],
    registry_name: (rules.registry_name || [])[0],
    registry_tech_admin: (rules.registr_tech_admin || [])[0],
    registry_url: (rules.registry_url || [])[0],
    regY: +(rules.regY || []).map(y => +y),
    renewOnTransfer: (rules.renewontransfer || [])[0] > 0,
    renewY: (rules.renewY || []).map(y => +y),
    regTime: +(rules.regtime || [])[0],
    root_server: (rules.root_server || [])[0],
    shieldWhois: (rules.shieldwhois || [])[0] > 0,
    tldGroup: rules.tldGroup || [],
    tldType: (rules.tldtype || [])[0],
    tld_created: (rules.tld_created || [])[0],
    transferMethod: (rules.transferMethod || [])[0],
    transferVerificationMethod: (rules.transferVerificationMethod || [])[0],
    trustee: (rules.trustee || [])[0] > 0,
    useAuthcode: (rules.useAuthcode || [])[0] > 0,
    subdomains: (rules.subdomains || '').split(' '),
    handlingTime: rules.handlingtime || {},
    prices: Object.fromEntries(
      Object.entries(rules.prices || {}).map(([kind, types]) => [kind, Object.fromEntries(
        Object.values(types).map(priceDetails => [priceDetails.desc, ({
          type: priceDetails.pricetype,
          desc: priceDetails.desc,
          currencies:
            {
              ...priceDetails.currencies,
              [priceDetails.currency]:
                {
                  price: priceDetails.price,
                  vat: priceDetails.vat,
                  total: priceDetails.total,
                }
            }
        })])
      )])
    ),
  };
}
