
    module.exports = {
      functional: true,
      render(_h, _vm) {
        const { _c, _v, data, children = [] } = _vm;

        const {
          class: classNames,
          staticClass,
          style,
          staticStyle,
          attrs = {},
          ...rest
        } = data;

        return _c(
          'svg',
          {
            class: [classNames,staticClass],
            style: [style,staticStyle],
            attrs: Object.assign({"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 1000 1000"}, attrs),
            ...rest,
          },
          children.concat([_c('path',{attrs:{"d":"M500 0q102 0 194 40t160 106 106 160 40 194-40 194-106 160-160 106-194 40-194-40-160-106T40 694 0 500t40-194 106-160T306 40 500 0zm0 71q-106 0-201 51l108 108q46-16 93-16t93 16l109-108q-96-51-202-51zM122 701l108-108q-16-46-16-93t16-93L122 298q-51 96-51 202t51 201zm378 228q106 0 202-51L593 770q-46 16-93 16t-93-16L299 878q95 51 201 51zm0-215q89 0 152-63t62-151-62-152-152-62-151 62-63 152 63 151 151 63zm270-121 108 108q51-95 51-201t-51-202L770 407q16 46 16 93t-16 93z"}})])
        )
      }
    }
  