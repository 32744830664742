<template>
  <color-button v-bind="$attrs" class="icon_button" :class="{solid: solid}" :style="btnStyles" v-on="$listeners">
    <slot />
  </color-button>
</template>

<script>
import colorButton from './ColorButton';

export default
{
  name: 'IconButton',
  components:
    {
      colorButton,
    },
  inheritAttrs: false,
  props:
    {
      size:
        {
          type: [Number, String],
          default: 36
        },
      solid:
        {
          type: Boolean,
          default: false
        }
    },
  computed:
    {
      btnStyles()
      {
        return {
          minWidth: this.size + 'px',
          minHeight: this.size + 'px',
          width: this.size + 'px',
          height: this.size + 'px',
        };
      }
    }
};
</script>

<style lang="scss">
  .icon_button.default_button
  {
    border-radius: 50%;
    padding: 0;
    overflow: hidden;
    justify-content: center;
    align-items: center;
  }

  .icon_button.solid
  {
    background-color: $icon_solid_background;
    border: 1px solid $dialog_border;
  }
</style>
