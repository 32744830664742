<template>
  <table class="data_table" :class="{striped_table: striped}">
    <thead>
      <tr class="nowrap">
        <th v-if="selectAll" width="1%" align="center" :title="$t('select_all')">
          <input v-if="selectableItems.length > 0" type="checkbox" class="checkbox ripple" :checked="value.length === items.length" @click="selectAllItems">
          <span v-else>&nbsp;&nbsp;&nbsp;</span>
        </th>
        <th
          v-for="(col,idx) in header" :key="idx" :class="mergedClass(col)" :align="col.align" :width="col.width"
          :style="{/*'font-weight': col.value === pagination.sortBy ? 'bold' : 'normal',*/ cursor: (col.sortable !== false && col.value) ? 'pointer' : 'default'}"
          @click="changeSorting(col)"
        >
          {{ col.text }}
          <my-icon v-if="col.value === pagination.sortBy" class="ml-1">
            <component :is="pagination.descending ? 'icon-up' : 'icon-down'" />
          </my-icon>
        </th>
      </tr>
    </thead>
    <tbody :class="{no_footer: !showPages && !$scopedSlots.footer}">
      <tr v-if="items.length === 0" align="center">
        <td :colspan="header.length + (selectAll ? 1 : 0)">
          <slot name="empty">{{ noDataText }}</slot>
        </td>
      </tr>
      <template v-else-if="rows">
        <slot name="rows" :row="sortedItems" />
      </template>
      <template v-else>
        <tr v-for="(item,idx) in sortedItems" :key="itemKey ? item[itemKey] : idx">
          <td v-if="selectAll" align="center">
            <input v-if="item[selectKey]" type="checkbox" class="checkbox ripple" :checked="value.includes(item)" @click="toggleSelection(item)">
          </td>
          <slot name="items" :item="item" />
        </tr>
      </template>
    </tbody>
    <tfoot>
      <slot name="footer" :header="header" />
      <tr v-if="showPages">
        <td :colspan="header.length + (selectAll ? 1 : 0)">
          <div class="flexbox align-center">
            <pagination v-model="pageObject.page" class="flex-auto" :count="totalItems || items.length" :page-size="pagination.rowsPerPage" />
            <div class="flexbox align-center">
              <template v-if="!hideTotal">{{ $t('checkout.total') }}: <strong class="pl-2 pr-3">{{ totalItems || items.length }}</strong></template>
              <form-field>
                <span class="ml-2 mr-2" slot="prepend">{{ rowsPerPageText }}:</span>
                <select v-model="pageObject.rowsPerPage" @change="persistPageSize">
                  <option v-for="size in listSize" :key="size" :value="size">{{ size }}</option>
                </select>
              </form-field>
            </div>
          </div>
        </td>
      </tr>
    </tfoot>
  </table>
</template>

<script>
import iconUp from '@/assets/img/icon/sort-up-duotone.svg';
import iconDown from '@/assets/img/icon/sort-down-duotone.svg';
import pagination from '@/components/ui/PaginationList';
import { strCompare } from '@/lib/util';
import events from '@/events';

export default
{
  name: 'DataTable',
  components:
    {
      pagination,
      /* eslint-disable vue/no-unused-components */
      iconUp,
      iconDown,
    },
  props:
    {
      rows:
        {
          // whether the ITEMS slot will be populated with TR children
          type: Boolean,
          default: false
        },
      header:
        {
          type: Array,
          required: true
        },
      items:
        {
          type: Array,
          default: () => []
        },
      striped:
        {
          type: Boolean,
          default: false
        },
      selectAll:
        {
          // whether to add a column in the header for CheckAll checkbox
          type: Boolean,
          default: false
        },
      selectKey:
        {
          // which property of the items determines whether to show a checkbox in 1st column
          type: String,
          default: 'selectable'
        },
      value:
        {
          type: Array,
          default: () => []
        },
      listSize:
        {
          type: Array,
          default: () => [10, 25, 50, 100]
        },
      pagination:
        {
          // must be declared as ".sync"
          type: Object,
          default: () => ({
            descending: false,
            sortBy: '',
            rowsPerPage: 0,
            page: 1,
            totalItems: 0,
          })
        },
      totalItems:
        {
          type: Number,
          default: 0
        },
      itemKey:
        {
          type: String,
          default: ''
        },
      noDataText:
        {
          type: String,
          default: 'No data'
        },
      rowsPerPageText:
        {
          type: String,
          default: 'Rows per page'
        },
      showPages:
        {
          type: Boolean,
          default: false
        },
      serverSort:
        {
          type: Boolean,
          default: false
        },
      hideTotal:
        {
          type: Boolean,
          default: false
        },
    },
  data()
  {
    return {
      pageObject: Object.assign({}, this.pagination),
    };
  },
  computed:
    {
      firstItem()
      {
        const res = this.totalItems ? 0 : ((this.pagination.page || 1) - 1) * (this.pagination.rowsPerPage || this.items.length);
        return res < this.items.length ? res : 0;
      },
      sortedItems()
      {
        const sortBy = this.pagination.sortBy.split('.');
        const len = sortBy.length;
        const descending = this.pagination.descending;
        return (sortBy && !this.totalItems
          ? this.items.slice().sort((a, b) =>
          {
            if (this.serverSort) return 0;
            let left = a;
            let right = b;
            for (let i = 0; i < len; i++)
            {
              if (left && sortBy[i] in left) left = left[sortBy[i]];
              else left = '';
              if (right && sortBy[i] in right) right = right[sortBy[i]];
              else right = '';
            }
            const res = strCompare(left, right);
            return descending ? -res : res;
          })
          : this.items).slice(this.firstItem, this.firstItem + (this.pagination.rowsPerPage || this.items.length));
      },
      selectableItems()
      {
        const key = this.selectKey;
        return this.sortedItems.filter(item => !!item[key]);
      },
    },
  watch:
    {
      pageObject:
        {
          immediate: true,
          deep: true,
          handler(newVal, oldVal)
          {
            if (oldVal && newVal.rowsPerPage !== oldVal.rowsPerPage && newVal.page > 1) this.pageObject.page = 1;
            else
            {
              this.$emit('update:pagination', newVal);
              this.$emit('paginate', newVal);
            }
          }
        }
    },
  methods:
    {
      selectAllItems()
      {
        this.$emit('input', this.value.length !== this.items.length ? this.selectableItems.slice() : []);
      },
      toggleSelection(item)
      {
        let result;
        const idx = this.value.indexOf(item);
        if (idx !== -1)
        {
          result = this.value.slice();
          result.splice(idx, 1);
        }
        else result = this.value.concat(item);
        this.$emit('input', result);
      },
      mergedClass(column)
      {
        switch (Object.toType(column.class))
        {
          case 'string':
            return column.class + (column.value ? ' pointer' : '');
          case 'array':
            return column.value ? column.class.concat('pointer') : column.class;
          case 'object':
            return column.value ? Object.assign({ pointer: true }, column.class) : column.class;
          default:
            return column.class;
        }
      },
      changeSorting(column)
      {
        if (column.sortable === false || !column.value) return;
        if (this.pagination.sortBy === column.value) this.pageObject.descending = !this.pageObject.descending;
        else
        {
          this.pageObject.sortBy = column.value;
          this.pageObject.descending = false;
        }
      },
      persistPageSize()
      {
        events.$emit('new-list-size', this.pageObject.rowsPerPage);
      },
    }
};
</script>

<style lang="scss" src="@/assets/scss/theme/table.scss"></style>
