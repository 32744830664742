
    module.exports = {
      functional: true,
      render(_h, _vm) {
        const { _c, _v, data, children = [] } = _vm;

        const {
          class: classNames,
          staticClass,
          style,
          staticStyle,
          attrs = {},
          ...rest
        } = data;

        return _c(
          'svg',
          {
            class: ["sort-up-duotone_svg__svg-inline--fa sort-up-duotone_svg__fa-sort-up sort-up-duotone_svg__fa-w-10",classNames,staticClass],
            style: [style,staticStyle],
            attrs: Object.assign({"aria-hidden":"true","data-prefix":"fad","data-icon":"sort-up","xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 320 512"}, attrs),
            ...rest,
          },
          children.concat([_c('g',{staticClass:"sort-up-duotone_svg__fa-group",attrs:{"fill":"currentColor"}},[_c('path',{staticClass:"sort-up-duotone_svg__fa-secondary",attrs:{"d":"M41.05 288.05h238c21.4 0 32.1 25.9 17 41l-119 119a23.9 23.9 0 0 1-33.8.1l-.1-.1-119.1-119c-15.05-15.05-4.4-41 17-41z","opacity":".4"}}),_c('path',{staticClass:"sort-up-duotone_svg__fa-primary",attrs:{"d":"m24.05 183.05 119.1-119A23.9 23.9 0 0 1 177 64a.94.94 0 0 1 .1.1l119 119c15.1 15.1 4.4 41-17 41h-238c-21.45-.05-32.1-25.95-17.05-41.05z"}})])])
        )
      }
    }
  