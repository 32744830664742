<template>
  <table class="qr_code">
    <tbody>
      <tr v-for="(row,rowIndex) in matrix" :key="rowIndex">
        <td v-for="(col,colIndex) in row" :key="colIndex" :class="{qr_1: col}" :style="dotStyle" />
      </tr>
    </tbody>
  </table>
</template>

<script>
import QRencoder from '@/lib/QR';

export default
{
  name: 'QRtable',
  props:
    {
      value:
        {
          type: String,
          required: true
        },
      dot:
        {
          type: [String, Number],
          default: 3
        }
    },
  computed:
    {
      matrix()
      {
        return QRencoder(this.value);
      },
      dotStyle()
      {
        const size = /[^0-9]/.test(this.dot) ? this.dot : this.dot + 'px';
        return {
          width: size,
          height: size
        };
      }
    },
};

</script>

<style lang="scss">
  .qr_code
  {
    border-spacing: 0;
    flex: 0 0 auto;

    td
    {
      padding: 0;
      background-color: white;
    }

    td.qr_1
    {
      background-color: black;
    }
  }
</style>
