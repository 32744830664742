
    module.exports = {
      functional: true,
      render(_h, _vm) {
        const { _c, _v, data, children = [] } = _vm;

        const {
          class: classNames,
          staticClass,
          style,
          staticStyle,
          attrs = {},
          ...rest
        } = data;

        return _c(
          'svg',
          {
            class: [classNames,staticClass],
            style: [style,staticStyle],
            attrs: Object.assign({"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 130 1000 820"}, attrs),
            ...rest,
          },
          children.concat([_c('path',{attrs:{"d":"M214 643q0-30-21-51t-50-21-51 21-21 51 21 50 51 21 50-21 21-50zm107-250q0-30-20-51t-51-21-50 21-21 51 21 50 50 21 51-21 20-50zm239 268 57-213q3-14-5-27t-21-16-27 3-17 22l-56 213q-33 3-60 25t-35 55q-11 43 11 81t66 50 81-11 50-66q9-33-4-65t-40-51zm369-18q0-30-21-51t-51-21-50 21-21 51 21 50 50 21 51-21 21-50zM571 286q0-30-20-51t-51-21-50 21-21 51 21 50 50 21 51-21 20-50zm250 107q0-30-20-51t-51-21-50 21-21 51 21 50 50 21 51-21 20-50zm179 250q0 145-79 269-10 17-30 17H109q-20 0-30-17Q0 789 0 643q0-102 40-194t106-160 160-107 194-39 194 39 160 107 106 160 40 194z"}})])
        )
      }
    }
  