import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

// Load all locales and remember context
function loadMessages()
{
  const context = require.context('./locales', true, /[A-Za-z0-9-_]+\.json$/i);

  const messages = context
    .keys()
    .map((key) => ({
      key,
      locale: key.match(/[A-Za-z0-9-_]+/i)[0]
    }))
    .reduce(
      (messageStrings, { key, locale }) => ({
        ...messageStrings,
        [locale]: context(key),
      }),
      {}
    );

  return {
    context,
    messages
  };
}

const { context, messages } = loadMessages();
const i18n = new VueI18n(
  {
    fallbackLocale: 'en',
    locale: 'en',
    silentTranslationWarn: true,
    messages,
  });

// Hot updates
if (module.hot)
{
  module.hot.accept(context.id, () =>
  {
    const { messages: newMessages } = loadMessages();

    Object.keys(newMessages)
      .filter((locale) => messages[locale] !== newMessages[locale])
      .forEach((locale) =>
      {
        messages[locale] = newMessages[locale];
        i18n.setLocaleMessage(locale, messages[locale]);
      });
  });
}

export default i18n;
