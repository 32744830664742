import i18n from '@/i18n';

export default
{
  state()
  {
    return {
      locale: null,
      currency: 'SEK',
      username: '',
    };
  },
  getters:
    {
      curLocale(state)
      {
        return state.locale;
      },
      getCurrency(state)
      {
        return state.currency || 'SEK';
      },
      getUsername(state)
      {
        return state.username;
      },
    },
  mutations:
    {
      setUsername(state, username)
      {
        state.username = username;
      },
      setLocale(state, lang)
      {
        if (window.i18n)
        {
          if (!(lang in window.i18n.messages)) lang = 'en';
          window.i18n.locale = lang;
        }
        state.locale = lang;
        document.querySelector('html').setAttribute('lang', lang);
        if (!window.isp) return;
        // update page title
        const route = window.isp.$route || {};
        let title = (route.meta || {}).title;
        if (typeof title === 'function') title = title(route, window.isp);
        if (title)
        {
          // eslint-disable-next-line @intlify/vue-i18n/no-dynamic-keys
          if (route.meta.localize) title = i18n.t(title);
          document.title = title;
        }
        else document.title = window.isp.whiteLabel.globals.brandName || window.isp.brandInfo.brandName;
      },
      setCurrency(state, cur)
      {
        state.currency = cur;
      },
    },
};
