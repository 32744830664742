<template>
  <pop-over>
    <flat-button class="country_selector pl-1 pr-1 pt-0 pb-0 ma-0 font-0 bold">
      <div class="current_flag" :style="{'background-image': 'url(' + baseURL + 'flags/' + curLocale + '.png' + ')'}" />
      <div class="flexbox flex-column align-center ml-1">
        <my-icon>
          <caret-up />
        </my-icon>
        <span class="upper" style="margin-top: -5px;">{{ $root.locale }}</span>
      </div>
    </flat-button>
    <my-card title-class="popover_header" slot="popover">
      <!-- <div slot="title">{{ $t('header.language_selector') }}</div> -->
      <div v-for="lang in $root.languages" :key="lang" v-close-popover class="popup_menu_item flexbox align-center pl-2 pr-2 pt-1 pb-1" @click="changeLocale(lang)">
        <my-icon :color="($i18n.messages[$i18n.locale] ? lang === $i18n.locale : lang === 'en') ? '' : 'transparent'">
          <icon-done />
        </my-icon>
        <div class="country_flag" :style="{'background-image': 'url(' + baseURL + 'flags/' + lang + '.png' + ')'}" />
        <div class="flex-auto font-1 bold">{{ $i18n.messages[lang].title }}</div>
      </div>
    </my-card>
  </pop-over>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import iconDone from '@/assets/img/icon/done.svg';
import caretUp from '@/assets/img/icon/caret-up.svg';

export default
{
  name: 'SelectLanguage',
  components:
    {
      iconDone,
      caretUp,
    },
  computed:
    {
      ...mapGetters(['curLocale']),
      baseURL()
      {
        return process.env.BASE_URL;
      }
    },
  methods:
    {
      ...mapMutations(['setLocale']),
      changeLocale(langCode)
      {
        this.setLocale(langCode);
        if (!this.$root.isLogged) return;
        const data = new FormData();
        data.append('language', langCode);
        this.$ajax(
          {
            method: 'POST',
            url: '/api/user/setusersettings/',
            login: this.$root.login,
            data,
          }
        );
      },
    }
};
</script>

<style lang="scss">
  @import '@/assets/scss/theme/footer.scss';

  .current_flag
  {
    border-radius: 50%;
    width: 24px;
    height: 24px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .country_flag
  {
    border-radius: 50%;
    width: 22px;
    height: 22px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-left: 4px;
    margin-right: 4px;
  }

  .country_selector
  {
    color: $footer_color;
  }
</style>
