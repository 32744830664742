<template>
  <!-- First row -->
  <div class="header_top">
    <div class="flexbox align-center flex-center">
      <!-- Homepage -->
      <router-link :to="{name: 'dashboard'}" :title="$t('login.homepage')">
        <!-- This loading spinner shows when any Vue component is lazy-loaded by async.js -->
        <img class="brand_logo" :src="logoURL">
      </router-link>
      <header-search class="flex-auto" />
      <router-link v-if="$root.whiteLabel.globals.enableServiceOrder" class="pa-2 default_button dark" :to="{name: 'orderService'}">
        <my-icon v-if="(menuOrder.meta || {}).icon" class="mr-2">
          <component :is="menuOrder.meta.icon" />
        </my-icon>
        <!-- eslint-disable @intlify/vue-i18n/no-dynamic-keys -->
        {{ (menuOrder.meta || {}).localize ? $t(menuOrder.meta.title) : (menuOrder.meta || {}).title }}
        <!-- eslint-enable @intlify/vue-i18n/no-dynamic-keys -->
      </router-link>
      <!--
      <select-currency/>
      <select-language/>
      -->
      <header-basket />
    </div>
  </div>
</template>

<script>
import headerSearch from './HeaderSearch';
// import selectCurrency from '../SelectCurrency'
// import selectLanguage from '../SelectLanguage'
import headerBasket from './HeaderBasket';

export default
{
  name: 'HeaderTop',
  components:
    {
      // selectCurrency,
      // selectLanguage,
      headerSearch,
      headerBasket,
    },
  computed:
  {
    logoURL()
    {
      return this.$root.ourApp ? (this.$root.appISP ? 'https://www.nameisp.com/brandbook/images/logos/nameISP_white.svg' : process.env.BASE_URL + 'logo.svg') : this.$root.whiteLabel.logo;
    },
    menuOrder()
    {
      return this.$root.routeNamesMap.orderService || {};
    },
  }
};
</script>

<style lang="scss">
  @import '@/assets/scss/theme/header.scss';

  .header_top
  {
    background-color: var(--header_top_bg, $header_bg);
    border-bottom: 1px solid var(--info_icon_bg, $header_color);

    .default_button.dark
    {
      color: var(--header_top_color, $header_color);

      &:hover
      {
        color: var(--btn_header_hover, #999);
      }
    }
  }

  .header_top > *
  {
    max-width: calc(90% - 2 * 20px);
  }

  .brand_logo
  {
    height: 50px;
    vertical-align: middle;
  }

</style>
