<template>
  <my-modal v-model="$root.suspended">
    <my-card>
      <template slot="title">{{ $t('login.billing_failure') }} &mdash; {{ $t('login.title') }}</template>
      <div class="pa-3">
        <strong>{{ $t('login.account_suspended') }}</strong>
      </div>
      <!--
      <div slot="footer" class="pl-2 pr-2 pb-2 flexbox justify-end">
        <my-button class="primary" @click="gotoLogin">{{ $t('buttons.close') }}</my-button>
      </div>
      -->
    </my-card>
  </my-modal>
</template>

<script>
export default
{
  name: 'SuspendedAccount',
  watch:
    {
      '$root.suspended'(newVal)
      {
        if (newVal)
        {
          this.$root.session = {};
        }
      }
    },
  methods:
    {
      /*gotoLogin()
      {
        this.$root.suspended = false;
        if (this.$route.name !== 'home')
        {
          this.$router.push({ name: 'home' }).catch(() => false);
        }
      }*/
    }
};
</script>
