import Vue from 'vue';
import { thousand } from '@/lib/util';

export function formatZoneOffset(minutes)
{
  const min = Math.abs(minutes) % 60;
  const hours = (Math.abs(minutes) - min) / 60;
  return (minutes > 0 ? '+' : '-') + String(hours).padStart(2, '0') + ':' + String(min).padStart(2, '0');
}

export function dateAuto(s)
{
  return (typeof s === 'string' || typeof s === 'number') ? new Date(s.indexOf('T') > 0 ? s : s + 'T00:00:00' + formatZoneOffset(new Date().getTimezoneOffset())) : s;
}

export function dateLocale(d, langCode)
{
  if (!d) return '';
  return dateAuto(d).toLocaleString(langCode || undefined,
    {
      year: 'numeric',
      day: 'numeric',
      month: 'short',
    });
}

export function timeLocale(d, langCode)
{
  if (!d) return '';
  return dateAuto(d).toLocaleString(langCode || undefined,
    {
      hour: 'numeric',
      minute: 'numeric',
    });
}

export function stampLocale(d, langCode)
{
  if (!d) return '';
  return dateAuto(d).toLocaleString(langCode || undefined,
    {
      year: 'numeric',
      day: 'numeric',
      month: 'short',
      hour: 'numeric',
      minute: 'numeric',
    });
}

export function stampLocaleLong(d, langCode)
{
  if (!d) return '';
  return dateAuto(d).toLocaleString(langCode || undefined,
    {
      year: 'numeric',
      day: 'numeric',
      month: 'short',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric'
    });
}

export function dateISO(d)
{
  if (!d) return '';
  return dateAuto(d).toISOString().substr(0, 10);
}

Vue.filter('thousand', thousand);
Vue.filter('dateISO', dateISO);
Vue.filter('dateLocale', dateLocale);
Vue.filter('timeLocale', timeLocale);
Vue.filter('stampLocale', stampLocale);
Vue.filter('stampLocaleLong', stampLocaleLong);

Vue.filter('ttl', function TTL(seconds, defaultSeconds)
{
  if (seconds < 0) return this.$t('domains.details.dns.ttl_options.custom');
  else if (seconds > 0)
  {
    if (seconds < 60) return window.i18n.tc('plurals.second_cnt', seconds);
    if (seconds < 3600) return window.i18n.tc('plurals.minute_cnt', Math.round(seconds / 60)) + ' (' + thousand(seconds, ' ') + ')';
    if (seconds < 86400) return window.i18n.tc('plurals.hour_cnt', Math.round(seconds / 3600)) + ' (' + thousand(seconds, ' ') + ')';
    return window.i18n.tc('plurals.day_cnt', Math.round(seconds / 86400)) + ' (' + thousand(seconds, ' ') + ')';
  }
  else return window.i18n.t('domains.details.dns.ttl_options.default', { seconds: defaultSeconds || 3600 });
});

Vue.filter('traffic', function traffic(value)
{
  if (typeof value !== 'number') return '';
  const factorMegabytes = 1024;
  if (value === 0) return '0';
  if (value < factorMegabytes) return value + ' bytes';
  else value = value / factorMegabytes;
  if (value < factorMegabytes) return value.toFixed(2) + ' KB';
  else value = value / factorMegabytes;
  if (value < factorMegabytes) return value.toFixed(2) + ' MB';
  else value = value / factorMegabytes;
  if (value < factorMegabytes) return value.toFixed(2) + ' GB';
  else return (value / factorMegabytes).toFixed(2) + ' TB';
});

Vue.filter('timeReadable', function timeReadable(seconds)
{
  if (typeof seconds !== 'number') return seconds;
  if (seconds < 60)
  {
    return seconds + 's';
  }
  if (seconds < 3600)
  {
    return Math.round(seconds / 60) + 'm';
  }
  if (seconds < 86400)
  {
    const minutes = seconds % 3600;
    return (
      Math.floor(seconds / 3600) +
      'h ' +
      (minutes > 60 ? Math.round(minutes / 60) + 'm' : '')
    );
  }
  const minutes = seconds % 3600;
  seconds = seconds - minutes;
  const hours = seconds % 86400;
  return (
    Math.floor(seconds / 86400) +
    'd ' +
    (hours > 3600 ? Math.round(hours / 3600) + 'h ' : '') +
    (minutes > 60 ? Math.round(minutes / 60) + 'm' : '')
  );
});
