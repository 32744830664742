import AsyncFactory from '../async';

export default
[
  {
    path: 'opentickets',
    name: 'openTickets',
    component: AsyncFactory(() => import(/* webpackChunkName: "Support" */ '@/views/support/OpenTickets')),
    meta:
      {
        title: 'support.open_tickets.title',
        localize: true,
        menu: (root) => !!(((root.whiteLabel || {}).globals || {}).menuSupport || {}).showTickets,
      },
    beforeEnter: (to, from, next) =>
    {
      if (!window.isp) next();
      else if (window.isp.whiteLabel.globals.showMenuSupport) next();
      else
      {
        const parent = window.isp.menu('support');
        const children = (parent.children || []).filter(item => (typeof item.meta.menu === 'function' ? item.meta.menu(window.isp) : item.meta.menu));
        next(children[0] || false);
      }
    },
  },
  {
    path: 'closedtickets',
    name: 'closedTickets',
    component: AsyncFactory(() => import(/* webpackChunkName: "Support" */ '@/views/support/ClosedTickets')),
    meta:
      {
        title: 'support.closed_tickets.title',
        localize: true,
        menu: (root) => !!(((root.whiteLabel || {}).globals || {}).menuSupport || {}).showTickets,
      },
    beforeEnter: (to, from, next) =>
    {
      if (!window.isp) next();
      else if (window.isp.whiteLabel.globals.showMenuSupport) next();
      else
      {
        const parent = window.isp.menu('support');
        const children = (parent.children || []).filter(item => (typeof item.meta.menu === 'function' ? item.meta.menu(window.isp) : item.meta.menu));
        next(children[0] || false);
      }
    },
  },
  {
    path: 'resources',
    name: 'supportResources',
    component: AsyncFactory(() => import(/* webpackChunkName: "Support" */ '@/views/support/DocsFiles')),
    meta:
      {
        title: 'support.resources.title',
        localize: true,
        menu: (root) => !!(((root.whiteLabel || {}).globals || {}).menuSupport || {}).showFiles,
      },
    beforeEnter: (to, from, next) =>
    {
      if (!window.isp) next();
      else if (window.isp.whiteLabel.globals.showMenuSupport) next();
      else
      {
        const parent = window.isp.menu('support');
        const children = (parent.children || []).filter(item => (typeof item.meta.menu === 'function' ? item.meta.menu(window.isp) : item.meta.menu));
        next(children[0] || false);
      }
    },
  },
  {
    path: 'tld-wiki/:tld?',
    name: 'tldWiki',
    component: AsyncFactory(() => import(/* webpackChunkName: "Support" */ '@/views/support/TldWiki')),
    meta:
      {
        title: 'support.wiki.title',
        localize: true,
        menu: (root) => !!(((root.whiteLabel || {}).globals || {}).menuSupport || {}).showTlds,
      },
    beforeEnter: (to, from, next) =>
    {
      if (!window.isp) next();
      else if (window.isp.whiteLabel.globals.showMenuSupport) next();
      else
      {
        const parent = window.isp.menu('support');
        const children = (parent.children || []).filter(item => (typeof item.meta.menu === 'function' ? item.meta.menu(window.isp) : item.meta.menu));
        next(children[0] || false);
      }
    },
  },
];
