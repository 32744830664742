
    module.exports = {
      functional: true,
      render(_h, _vm) {
        const { _c, _v, data, children = [] } = _vm;

        const {
          class: classNames,
          staticClass,
          style,
          staticStyle,
          attrs = {},
          ...rest
        } = data;

        return _c(
          'svg',
          {
            class: [classNames,staticClass],
            style: [style,staticStyle],
            attrs: Object.assign({"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 90 612.022 611.289"}, attrs),
            ...rest,
          },
          children.concat([_c('path',{attrs:{"d":"M534.949 319.683c21.525 0 39.626 7.583 54.302 22.748C603.928 357.597 611.51 375.453 612 396c.488 20.547-7.094 38.403-22.749 53.568-15.654 15.166-33.755 22.504-54.302 22.015H382.317V624.95c0 21.036-7.339 38.892-22.015 53.568-14.677 14.676-33.021 22.259-55.036 22.748-22.014.489-39.87-7.094-53.568-22.748-13.697-15.655-21.036-33.511-22.014-53.568V471.583H76.317c-21.525 0-39.626-7.338-54.303-22.015C7.338 434.892 0 417.036 0 396s7.338-38.892 22.014-53.568c14.677-14.676 32.777-22.259 54.303-22.748h153.368V166.316c0-20.546 7.338-38.402 22.014-53.568C266.374 97.583 284.23 90 305.266 90s39.381 7.583 55.036 22.748c15.654 15.166 22.993 33.022 22.015 53.568v153.367h152.632z"}})])
        )
      }
    }
  