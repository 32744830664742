<template>
  <div class="form_field">
    <label v-if="label" style="text-align: initial;">{{ label }}</label>
    <div class="form_group" @input="setDirty" @change="setDirty">
      <div v-if="$slots.prepend" class="form_input_prepend">
        <slot name="prepend" />
      </div>
      <slot />
      <div v-if="$slots.append" class="form_input_append">
        <slot name="append" />
      </div>
    </div>
    <div v-if="error && (dirty || force)" class="field_error">{{ error }}</div>
  </div>
</template>

<script>
export default
{
  name: 'FormField',
  inject:
    {
      form:
        {
          default: null
        }
    },
  props:
    {
      label:
        {
          type: String,
          default: ''
        },
      error:
        {
          type: String,
          default: null
        },
      force:
        {
          type: Boolean,
          default: false
        },
    },
  data()
  {
    return {
      dirty: false,
    };
  },
  computed:
    {
      valid() // eslint-disable-line vue/no-unused-properties
      {
        return !this.error;
      }
    },
  created()
  {
    this.form && this.form.register(this);
  },
  beforeDestroy()
  {
    this.form && this.form.unregister(this);
  },
  methods:
    {
      setDirty(value)
      {
        this.dirty = value;
      }
    }
};
</script>

<style lang="scss">
  $field_radius: nth($corners, 2);

  .form_field
  {
    display: flex;
    flex-direction: column;
  }

  .form_field + .form_field
  {
    margin-top: 8px;
  }

  .form_field > label
  {
    padding-bottom: 4px;
  }

  .form_field .vdp-datepicker > *:first-child
  {
    display: flex;
    height: 100%;
  }

  .form_group
  {
    border: 1px solid $dialog_border;
    border-radius: $field_radius;
    display: flex;
    align-items: center;
    background-color: $input_prepend;
    flex-grow: 1;
  }

  .form_group:focus-within
  {
    border-color: var(--btn_primary_bg, $input_border);
  }

  .form_group input,
  .form_group select,
  .form_group textarea
  {
    border: none;
    flex: 1 1 auto;
    padding: 0 6px;
    margin: 0;
    min-height: calc(1.5rem + 8px);
    min-width: 48px;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: $field_radius; /* probably should be conditional - based on the absence of prepend/append slot contents */
  }

  .form_group select
  {
    background: white;
  }

  .form_group textarea
  {
    height: auto;
  }

  .form_group input[type="file"]
  {
    padding: 0;
    min-height: 0;
  }

  .form_group input:focus,
  .form_group select:focus,
  .form_group textarea:focus
  {
    outline: none;
  }

  .form_group input:disabled,
  .form_group select:disabled,
  .form_group textarea:disabled
  {
    background-color: $input_disabled;
  }

  .form_group > *:not(.form_input_prepend):not(.form_input_append)
  {
    align-self: stretch;
  }

  .form_input_prepend,
  .form_input_append
  {
    /* background-color: $input_prepend; */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .form_input_prepend
  {
    border-top-left-radius: $field_radius;
    border-bottom-left-radius: $field_radius;
  }

  .form_input_append
  {
    border-top-right-radius: $field_radius;
    border-bottom-right-radius: $field_radius;
  }

  .field_error
  {
    font-family: 'Segoe UI', Tahoma, sans-serif;
    font-size: 85%;
    color: red;
    margin: 3px 0;
  }
</style>
