export class ZoneException extends Error
{
  constructor(kind, ...params)
  {
    super(...params);
    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace)
    {
      Error.captureStackTrace(this, ZoneException);
    }

    this.name = 'ZoneException';
    this.kind = kind;
  }
}
