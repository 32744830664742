import AsyncFactory from '../async';

export default
[
  {
    path: 'status',
    name: 'domainDetails',
    component: AsyncFactory(() => import(/* webpackChunkName: "Manage" */ '@/views/domains/manage/status/DomainDetails')),
    meta:
      {
        title: 'domains.details.domain.title',
        localize: true,
        menu: true
      },
  },
  {
    path: 'dns/:domain?',
    name: 'domainDNS',
    component: AsyncFactory(() => import(/* webpackChunkName: "Manage" */ '@/views/domains/manage/dns/DomainDNS')),
    meta:
      {
        title: 'domains.details.dns.title',
        localize: true,
        menu: true,
        premium: true,
      },
  },
  {
    path: 'dnssec',
    name: 'domainDNSSEC',
    component: AsyncFactory(() => import(/* webpackChunkName: "Manage" */ '@/views/domains/manage/dns_sec/DomainDnsSec')),
    meta:
      {
        title: 'domains.details.dnssec.title',
        localize: true,
        menu: true
      },
  },
];
