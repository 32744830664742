<template>
  <my-modal v-model="show" persistent :escape="false">
    <form v-loading="loading > 0" style="max-width: 460px;" @submit.prevent="askReset">
      <my-card>
        <template slot="title">{{ $t('login.two_step_reset') }}</template>
        <div class="pa-3">
          <info-box>
            {{ $t('login.two_step_reset_info') }}
          </info-box>
          <div class="center py-4" :class="{error_color: missingPhone}">
            <template v-if="missingPhone">
              <my-icon class="mr-2">
                <icon-attention />
              </my-icon>
              {{ $t('login.no_phone') }}
            </template>
            <template v-else>{{ $t('login.two_step_phone') }} = {{ phone }}</template>
          </div>
          <div v-if="nextStep === 'validate'" class="center">
            <div class="inline-flex flex-column">
              <form-field>
                <div class="pl-2 pr-2" slot="prepend">{{ $t('login.two_step_code') }}</div>
                <input ref="code" v-model.trim="code" required minlength="6" maxlength="6" inputmode="numeric" pattern="[0-9]*" style="width: 100px;">
              </form-field>
              <div v-if="wrongPin" class="flex-center center error_color pt-2">
                <my-icon class="mr-2">
                  <icon-attention />
                </my-icon>
                {{ $t('login.wrong_pin') }}
              </div>
            </div>
          </div>
        </div>
        <div class="pl-2 pr-2 pb-2 flexbox justify-center" slot="footer">
          <my-button class="primary" :disabled="missingPhone || (wasSent && countdown)" @click="askSMS">{{ (wasSent && countdown) ? `(${countdown}) ` : '' }}{{ wasSent ? $t('signup.resend') : $t('login.two_step_sms') }}</my-button>
          <my-button class="primary" type="submit" :disabled="!code">{{ $t('buttons.reset') }}</my-button>
          <my-button class="secondary" @click="show = false">{{ $t('buttons.cancel') }}</my-button>
        </div>
      </my-card>
    </form>
  </my-modal>
</template>

<script>
import infoBox from '@/components/widget/InfoBox';
import iconAttention from '@/assets/img/icon/attention.svg';

export default
{
  name: 'TwoFactorReset',
  components:
    {
      infoBox,
      iconAttention,
    },
  props:
    {
      value:
        {
          type: Boolean,
          default: false
        }
    },
  data()
  {
    return {
      nextStep: '',
      phone: '',
      code: '',
      loading: 0,
      missingPhone: false,
      wrongPin: false,
      wasSent: false,
      countdown: 90,
    };
  },
  computed:
    {
      show:
        {
          get()
          {
            return this.value;
          },
          set(val)
          {
            this.$emit('input', val);
          }
        },
    },
  watch:
    {
      value(newVal)
      {
        if (newVal)
        {
          this.nextStep = '';
          this.phone = '';
          this.code = '';
          this.getPhone();
        }
      }
    },
  methods:
    {
      getPhone()
      {
        const data = new FormData();
        this.$ajax(
          {
            method: 'POST',
            url: '/api/user/reset2step/',
            data,
            okay: (response) =>
            {
              this.nextStep = response['2step'].nextstep;
              this.phone = response['2step'].phone;
            },
            fail: (stat, msg) =>
            {
              if (stat == 2304) this.missingPhone = true;
              else this.$root.showFailed(msg);
            },
            spinner: (show) =>
            {
              this.loading += show ? 1 : this.loading > 0 ? -1 : 0;
            }
          }
        );
      },
      askSMS()
      {
        this.missingPhone = false;
        this.wrongPin = false;
        const data = new FormData();
        data.append('step', this.nextStep);
        this.$ajax(
          {
            method: 'POST',
            url: '/api/user/reset2step/',
            data,
            okay: (response) =>
            {
              this.nextStep = response['2step'].nextstep;
              this.code = '';
              this.countdown = 90;
              this.wasSent = true;
              this.count();
              this.$nextTick(() =>
              {
                this.$refs.code.focus();
              });
            },
            fail: (stat, msg) =>
            {
              if (stat == 2304) this.missingPhone = true;
              else this.$root.showFailed(msg);
            },
            spinner: (show) =>
            {
              this.loading += show ? 1 : this.loading > 0 ? -1 : 0;
            }
          }
        );
      },
      count()
      {
        setTimeout(() =>
        {
          this.countdown--;
          if (this.countdown > 0) this.count();
        }, 1000);
      },
      askReset()
      {
        this.missingPhone = false;
        this.wrongPin = false;
        const data = new FormData();
        data.append('step', this.nextStep);
        data.append('resetcode', this.code);
        this.$ajax(
          {
            method: 'POST',
            url: '/api/user/reset2step/',
            data,
            okay: () =>
            {
              window.location.reload();
            },
            fail: (stat, msg) =>
            {
              if (stat == 2304) this.missingPhone = true;
              else if (stat == 2200) this.wrongPin = true;
              else this.$root.showFailed(msg);
            },
            spinner: (show) =>
            {
              this.loading += show ? 1 : this.loading > 0 ? -1 : 0;
            }
          }
        );
      },
    }
};
</script>
