
    module.exports = {
      functional: true,
      render(_h, _vm) {
        const { _c, _v, data, children = [] } = _vm;

        const {
          class: classNames,
          staticClass,
          style,
          staticStyle,
          attrs = {},
          ...rest
        } = data;

        return _c(
          'svg',
          {
            class: [classNames,staticClass],
            style: [style,staticStyle],
            attrs: Object.assign({"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 920 1000"}, attrs),
            ...rest,
          },
          children.concat([_c('path',{attrs:{"d":"M454 40q190-2 326 130t140 322q2 190-131 327T466 960q-190 2-327-131T0 506q-4-190 130-327T454 40zm-2 740q30 0 49-19t19-47q2-30-17-49t-49-19h-2q-28 0-47 18t-21 46q0 30 19 49t47 21h2zm166-328q26-34 26-78 0-78-54-116-52-38-134-38-64 0-104 26-68 42-72 146v4h110v-4q0-26 16-54 16-24 54-24 40 0 52 20 16 20 16 44 0 18-16 40-8 12-20 20l-6 4q-6 4-16 11t-20 15-21 17-17 17q-14 20-18 78v8h108v-4q0-12 4-28 6-20 28-36l28-18q46-34 56-50z"}})])
        )
      }
    }
  