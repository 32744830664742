
    module.exports = {
      functional: true,
      render(_h, _vm) {
        const { _c, _v, data, children = [] } = _vm;

        const {
          class: classNames,
          staticClass,
          style,
          staticStyle,
          attrs = {},
          ...rest
        } = data;

        return _c(
          'svg',
          {
            class: [classNames,staticClass],
            style: [style,staticStyle],
            attrs: Object.assign({"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 612 612"}, attrs),
            ...rest,
          },
          children.concat([_c('path',{attrs:{"d":"M7.5 527.564c0-20.773 7.502-38.661 22.505-53.663l168.78-168.781-168.78-169.648C15.002 121.047 7.5 103.446 7.5 82.673c0-20.771 7.502-38.37 22.505-52.798C45.008 15.449 62.607 8.236 82.804 8.236c20.195 0 37.795 7.213 52.798 21.639L305.247 199.52 474.026 29.875c14.426-14.426 32.026-21.639 52.8-21.639 20.772 0 38.371 7.213 52.798 21.639 14.428 14.427 21.64 32.025 21.64 52.798 0 20.773-7.213 38.373-21.64 52.801L410.844 305.12 579.623 473.9c14.427 15.004 21.64 32.891 21.64 53.664s-7.213 38.371-21.64 52.798C565.197 594.788 547.598 602 526.824 602c-20.772 0-38.373-7.213-52.8-21.639L305.246 411.582 135.601 580.361C121.176 594.787 103.575 602 82.803 602c-20.773 0-38.372-7.213-52.8-21.639C15.002 565.36 7.5 547.76 7.5 527.564z"}})])
        )
      }
    }
  