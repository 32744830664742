
    module.exports = {
      functional: true,
      render(_h, _vm) {
        const { _c, _v, data, children = [] } = _vm;

        const {
          class: classNames,
          staticClass,
          style,
          staticStyle,
          attrs = {},
          ...rest
        } = data;

        return _c(
          'svg',
          {
            class: [classNames,staticClass],
            style: [style,staticStyle],
            attrs: Object.assign({"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 512 512"}, attrs),
            ...rest,
          },
          children.concat([_c('path',{attrs:{"d":"M322.3 268c9.4 2.6 19.3 4 29.7 4 60.5 0 109.8-48 111.9-108l-25.3 25.3c-12 12-28.3 18.7-45.3 18.7H368c-35.3 0-64-28.7-64-64v-25.4c0-17 6.7-33.3 18.7-45.3L348 48.1c-60 2-108 51.4-108 111.9 0 10.3 1.4 20.3 4 29.7 4.6 16.6-.2 34.4-12.4 46.6L57.1 410.7c-5.9 5.8-9.1 13.8-9.1 22.1C48 450 62 464 79.2 464c8.3 0 16.2-3.3 22.1-9.1l174.4-174.5c12.2-12.2 30-16.9 46.6-12.4zM424.6 39.4l-67.9 67.9c-3 3-4.7 7.1-4.7 11.3V144c0 8.8 7.2 16 16 16h25.4c4.2 0 8.3-1.7 11.3-4.7l67.9-67.9c7.2-7.2 19.3-5.9 23.7 3.3 10.1 21 15.7 44.5 15.7 69.3 0 88.4-71.6 160-160 160-14.7 0-28.9-2-42.3-5.7L135.2 488.8a79.09 79.09 0 0 1-56 23.2C35.5 512 0 476.5 0 432.8c0-21 8.3-41.1 23.2-56l174.5-174.5c-3.7-13.4-5.7-27.6-5.7-42.3C192 71.6 263.6 0 352 0c24.8 0 48.3 5.7 69.3 15.7 9.2 4.4 10.5 16.5 3.3 23.7zM88 408a16 16 0 1 1 0 32 16 16 0 1 1 0-32z"}})])
        )
      }
    }
  