<template>
  <div class="header_bottom">
    <div class="flexbox mx-auto">
      <div v-if="$route.name != 'home'" class="flexbox main_menu">
        <router-link
          v-for="view in menuItems" :key="view.path"
          class="pa-2 relative" :to="view.path"
        >
          <my-icon v-if="view.meta.icon">
            <component :is="view.meta.icon" />
          </my-icon>
          <!-- eslint-disable @intlify/vue-i18n/no-dynamic-keys -->
          {{ view.meta.localize ? $t(view.meta.title) : view.meta.title }}
          <!-- eslint-enable @intlify/vue-i18n/no-dynamic-keys -->
          <badge v-if="view.path.startsWith('/support') && getCountUnread > 0" class="absolute badge_tickets">
            {{ getCountUnread }}
          </badge>
        </router-link>
      </div>
      <div class="flex-auto flexbox justify-center">
        <div v-loading="false/*$root.spin > 0*/" style="flex: 0 0 48px;" />
      </div>
      <div class="flexbox align-center main_menu">
        <header-account @exit="logout" />
      </div>
    </div>
  </div>
</template>

<script>
import headerAccount from './HeaderAccount';
import badge from '@/components/ui/BadgeItem';
import { mapGetters } from 'vuex';

export default
{
  name: 'HeaderMenu',
  components:
    {
      headerAccount,
      badge,
    },
  computed:
    {
      ...mapGetters(['getCountUnread']),
      menuItems()
      {
        return this.$router.options.routes.filter(item => item.meta && (typeof item.meta.menu === 'function' ? item.meta.menu(this.$root) : item.meta.menu) && (item.meta.dev ? this.$root.isDEV : true));
      }
    },
  methods:
    {
      logout()
      {
        this.$root.$emit('logout');
      },
    }
};
</script>

<style lang="scss">
  @import '@/assets/scss/theme/header.scss';

  .header_bottom
  {
    background-color: var(--header_bottom_bg, $header_bg);
    color: var(--header_bottom_color, $header_color);
    min-height: 50px;
  }

  .header_bottom > *
  {
    max-width: calc(90% - 2 * 20px);
  }

  .badge_tickets
  {
    right: -6px;
    top: -2px;
    background-color: var(--badge_color);
    color: var(--badge_bg);
  }
</style>
