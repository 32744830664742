
    module.exports = {
      functional: true,
      render(_h, _vm) {
        const { _c, _v, data, children = [] } = _vm;

        const {
          class: classNames,
          staticClass,
          style,
          staticStyle,
          attrs = {},
          ...rest
        } = data;

        return _c(
          'svg',
          {
            class: [classNames,staticClass],
            style: [style,staticStyle],
            attrs: Object.assign({"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 571.4 1000"}, attrs),
            ...rest,
          },
          children.concat([_c('path',{attrs:{"d":"M571 679q0 14-10 25t-25 10H36q-15 0-25-10T0 679t11-26l250-250q10-10 25-10t25 10l250 250q10 11 10 26z"}})])
        )
      }
    }
  