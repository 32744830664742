import AsyncFactory from '../async';

export default
[
  {
    path: 'profile',
    name: 'myProfile',
    component: AsyncFactory(() => import(/* webpackChunkName: "Account" */ '@/views/account/ProfileInfo')),
    meta:
      {
        title: 'account.profile.title',
        localize: true,
        menu: (root) => !!(((root.whiteLabel || {}).globals || {}).menuAccount || {}).showProfile,
      },
    beforeEnter: (to, from, next) =>
    {
      if (!window.isp) next();
      else if (window.isp.whiteLabel.globals.showMenuAccount) next();
      else
      {
        const parent = window.isp.menu('myAccount');
        const children = (parent.children || []).filter(item => (typeof item.meta.menu === 'function' ? item.meta.menu(window.isp) : item.meta.menu));
        next(children[0] || false);
      }
    },
  },
  {
    path: 'settings',
    name: 'profileSettings',
    component: AsyncFactory(() => import(/* webpackChunkName: "Account" */ '@/views/account/AccountSettings')),
    meta:
      {
        title: 'account.settings.title',
        localize: true,
        menu: (root) => !!(((root.whiteLabel || {}).globals || {}).menuAccount || {}).showSettings,
      },
    beforeEnter: (to, from, next) =>
    {
      if (!window.isp) next();
      else if (window.isp.whiteLabel.globals.showMenuAccount) next();
      else
      {
        const parent = window.isp.menu('myAccount');
        const children = (parent.children || []).filter(item => (typeof item.meta.menu === 'function' ? item.meta.menu(window.isp) : item.meta.menu));
        next(children[0] || false);
      }
    },
  },
  {
    path: 'users',
    name: 'profileUsers',
    component: AsyncFactory(() => import(/* webpackChunkName: "Account" */ '@/views/account/AccountUsers')),
    meta:
      {
        title: 'account.users.title',
        localize: true,
        menu: (root) => !!((((root.whiteLabel || {}).globals || {}).menuAccount || {}).showUsers) || process.env.NODE_ENV === 'development' || root.permissions.account_create_user,
      },
    beforeEnter: (to, from, next) =>
    {
      if (!window.isp) next();
      else if (window.isp.whiteLabel.globals.showMenuAccount) next();
      else
      {
        const parent = window.isp.menu('myAccount');
        const children = (parent.children || []).filter(item => (typeof item.meta.menu === 'function' ? item.meta.menu(window.isp) : item.meta.menu));
        next(children[0] || false);
      }
    },
  },
  {
    path: 'security',
    name: 'profileSecurity',
    component: AsyncFactory(() => import(/* webpackChunkName: "Account" */ '@/views/account/AccountSecurity')),
    meta:
      {
        title: 'account.security.title',
        localize: true,
        menu: (root) => !!((((root.whiteLabel || {}).globals || {}).menuAccount || {}).showSecurity) || process.env.NODE_ENV === 'development' || root.permissions.account_setaccountsettings,
      },
    beforeEnter: (to, from, next) =>
    {
      if (!window.isp) next();
      else if (window.isp.whiteLabel.globals.showMenuAccount) next();
      else
      {
        const parent = window.isp.menu('myAccount');
        const children = (parent.children || []).filter(item => (typeof item.meta.menu === 'function' ? item.meta.menu(window.isp) : item.meta.menu));
        next(children[0] || false);
      }
    },
  },
  {
    path: 'whitelabel',
    name: 'whiteLabel',
    component: AsyncFactory(() => import(/* webpackChunkName: "Account" */ '@/views/account/WhiteLabel')),
    meta:
      {
        title: 'account.whitelabel.title',
        localize: true,
        menu: false,
      },
    beforeEnter: (to, from, next) =>
    {
      if (!window.isp) next();
      else if (window.isp.whiteLabel.globals.showMenuAccount) next();
      else
      {
        const parent = window.isp.menu('myAccount');
        const children = (parent.children || []).filter(item => (typeof item.meta.menu === 'function' ? item.meta.menu(window.isp) : item.meta.menu));
        next(children[0] || false);
      }
    },
  },
];
