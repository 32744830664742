import { isObject, isArray, uuid } from './util';
import events from '@/events';
import { addBreadcrumb /* , captureMessage, withScope as SentryScope, setTags as SentryTags */ } from '@sentry/browser';
import pkg from '@/../package.json';

const xhrList = []; // used to handle cancellation of all AJAX requests when leaving a route

export function cancelAJAX()
{
  const valid = [];
  while (xhrList.length > 0)
  {
    const req = xhrList.pop();
    if (req.$params.keep === true) valid.push(req);
    else req.abort();
  }
  Array.prototype.splice.apply(xhrList, [0, 0].concat(valid));
}

/*
 * Get cross browser xhr object
 *
 * Copyright (C) 2011 Jed Schmidt <http://jed.is>
 * More: https://gist.github.com/993585
 */

function getXHR(a)
{
  for (a = 0; a < 4; a--)
  {
    try
    {
      /* eslint-disable no-undef */
      return a
        ? new ActiveXObject(
          [
            'Msxml2', 'Msxml3', 'Microsoft'
          ][a] + '.XMLHTTP'
        )
        : new XMLHttpRequest();
    }
    catch (e)
    {}
  }
  return null;
}

/*
  params:
  {
    spinner [function] (show Boolean)
    timeout [number] milliseconds
    fail [function] (statusCode, errorMessage)
      statusCode:
        -1 = timeout
        -2 = network error
       595 = API user error (invalid params or no permissions)
       597 = invalid JSON response
       598 = API runtime-error (PHP error)
       599 = error from XHR.send()
    okay [function] (responseText, XHR object)
    progress [function] (isUpload Boolean, currentBytes, total Bytes)
    data [Object / File / FormData / String]
    user [function] (userid)
    headers [Object]
    keep [boolean] - TRUE if it should not be automatically aborted or route change
  }
 */
export default function ajax(params)
{
  if (!params.url)
  {
    console.error('Empty URL');
    return undefined;
  }
  params.$root = this.$root;
  const skipFail = ('fail' in params) && !params.fail;
  const hasFail = (params.fail && typeof params.fail === 'function');
  const hasOkay = (params.okay && typeof params.okay === 'function');
  const hasSpin = (params.spinner && typeof params.spinner === 'function');
  const xhr = getXHR();
  xhr.withCredentials = !('credentials' in params); // otherwise PHPSESSID cookie is not sent cross-origin !!!!!
  let timer, timeout;
  // set timeout
  if (params.timeout && typeof params.timeout === 'number') timeout = params.timeout;
  else if (this && this.$root && (this.$root.cfg || {}).ajaxTimeout) timeout = (this.$root.cfg || {}).ajaxTimeout;
  if (timeout)
  {
    timer = setTimeout(function xhrTimeout()
    {
      if (!xhr) return;
      params.aborted = true;
      xhr.onabort = null;
      xhr.abort();
      console.log('XHR timeout:', params.method, params.url);
      if (hasSpin) params.spinner(false);
      if (hasFail) params.fail(-1, 'API request timed out');
      else if (!skipFail) events.$emit('ajax-error', -1, 'API request timed out');
      addBreadcrumb({
        type: 'http',
        level: 'info',
        data:
          {
            url: params.url,
            method: params.method,
            reason: 'Timeout',
          }
      });
      reportSlack('API request to `' + params.url + '` has timed out.', params);
    }, timeout);
  }

  xhr.onabort = function ajaxAborted()
  {
    let idx = xhrList.indexOf(xhr);
    if (idx !== -1) xhrList.splice(idx, 1);
    if (params.ajaxList)
    {
      idx = params.ajaxList.indexOf(xhr);
      if (idx !== -1) params.ajaxList.splice(idx, 1);
    }
  };

  xhr.onreadystatechange = function ajaxReady()
  {
    if (xhr.readyState !== 4) return;
    if (timer) clearTimeout(timer);
    if (hasSpin) params.spinner(false);
    if (params.ajaxList)
    {
      const idx = params.ajaxList.indexOf(xhr);
      if (idx !== -1) params.ajaxList.splice(idx, 1);
    }
    const idxAbort = xhrList.indexOf(xhr);
    if (idxAbort == -1) return; // request has been aborted
    else xhrList.splice(idxAbort, 1);
    /*
    if (window.performance && window.performance.measure && navigator.sendBeacon)
    {
      let wnd = window.performance, mes_id = 'xhr_' + params.uuid;
      wnd.mark('end_' + params.uuid);
      wnd.measure(mes_id, 'start_' + params.uuid, 'end_' + params.uuid);
      wnd.clearMarks('start_' + params.uuid);
      wnd.clearMarks('end_' + params.uuid);
      let beakon = '', perf = window.performance.getEntriesByType('measure'), len = perf.length;
      for (let i = 0; i < len; i++)
        if (perf[i].name === mes_id)
        {
          beakon = JSON.stringify(
          {
            url: params.url,
            time: perf[i].duration // msec
          });
          break;
        }
      wnd.clearMeasures(mes_id);

      navigator.sendBeacon(process.env.BEACON_URL, beakon); // POST request
    }
    */
    // if (params.user && typeof params.user === 'function') params.user(xhr.getResponseHeader('X-ID'));

    // error handling
    if (xhr.status >= 200 && xhr.status < 300)
    {
      params.response = xhr.responseText;
      let js;
      try
      {
        js = JSON.parse(xhr.responseText);
        params.json = js;
      }
      catch (err)
      {
        const resp = xhr.responseText;
        console.log(resp);
        if (hasFail) params.fail(597, monospaceText(resp));
        else if (!skipFail)
        {
          events.$emit('ajax-error', 597, monospaceText(resp), {
            input: params,
            output: xhr.responseText
          });
        }
        /*
        else
        {
          console.log(err + " ==> IN: " + xhr.responseText.substr(0, 1000));
          if (hasFail) params.fail(597, "JSON parse error: " + err);
          else if (!skipFail)
          {
            events.$emit('ajax-error', 597, "JSON parse error: " + err, {
              input: params,
              output: xhr.responseText
            });
          }
        }
        */
        reportSlack('API response from `' + params.url + (resp ? '` is not a valid JSON\n```\n' + resp + '\n```\n' : '` was EMPTY\n'), params);
        return;
      }
      if (process.env.NODE_ENV === 'production')
      {
        addBreadcrumb({
          type: 'http',
          category: 'xhr',
          data:
            {
              url: params.url,
              method: params.method,
              response: xhr.responseText,
              payload: params.data ? [...params.data] : null,
            }
        });
      }

      if (isObject(js))
      {
        if (typeof js.error === 'object' && js.error.type)
        {
          // handle PHP run-time errors
          let tabs = 1;
          const delim = '#';
          const spc = ' ';
          let msg = '';
          let err = js.error;

          if (err.text) msg += err.text + '\n';
          if (err.trace.length)
          {
            while (err.trace.length)
            {
              const tr = err.trace.shift();
              msg += Array(tabs + 1).join(delim) + ' ' + tr.line + ', ' + tr.file + '\n';
              tabs++;
              msg += Array(tabs + 1).join(spc) + (tr.class ? tr.class + '::' : '') + tr.function.name + '(' + tr.function.args.join(', ') + ')\n';
            }
          }
          msg += Array(tabs + 1).join(delim) + ' ' + err.line + ', ' + err.file + '\n';
          if (isObject(err.sql))
          {
            err = err.sql;
            msg += '\nSTATE = ' + err.state + '\n' +
              (err.text ? err.text + '\n' : '') +
              (err.detail ? err.detail + '\n' : '') +
              (err.context ? err.context : '');
          }
          console.log(msg);
          if (hasFail) params.fail(598, monospaceText(msg));
          else if (!skipFail) events.$emit('ajax-error', 598, monospaceText(msg), { error: err });
        }
        else if ((typeof js.code === 'string' || typeof js.code === 'number') && typeof js.desc === 'string')
        {
          // 1000 = Command completed successfully
          // 1300 = Command completed successfully; no messages
          // 2001 = Command syntax error
          // 2002 = Command use error
          // 2003 = Required parameter missing
          // 2004 = Parameter value range error
          // 2104 = Billing failure
          // 2105 = Object is not eligible for renewal
          // 2106 = Object is not eligible for transfer
          // 2200 = Authentication error
          // 2201 = Authorization error
          // 2202 = Wrong password or username
          // 2203 = Two-factor authentication required
          // 2303 = Object does not exist
          // 2304 = Object status prohibits operation
          // 2400 = Command failed
          // 2500 = Command failed; server closing connection
          if (+js.code === 1000 || +js.code === 1300)
          {
            // no errors
            if (hasOkay) params.okay(js, xhr);
          }
          // API error
          else if (+js.code === 2200 || (js.session && js.session.status === 'expired'))
          {
            // session expired or not logged-in
            if (params.login && typeof params.login === 'function') params.login(xhr, params); // it will show the Login modal and then retry with "xhr.$perform(xhr, params)"
          }
          else if (+js.code === 2203)
          {
            // Two-factor authentication required
            if (params.login && typeof params.login === 'function')
            {
              params.login(xhr, params, js['2step'] || {}); // it will show the Login modal and then retry with "xhr.$perform(xhr, params)"
            }
          }
          else if (+js.code === 2104)
          {
            params.$root.suspended = true;
          }
          else
          {
            let msg = js.desc;
            if (+js.code === 2003 && js.missing) msg += ' - ' + js.missing.join(', ');
            if (hasFail) params.fail(+js.code, monospaceText(msg), js);
            else if (!skipFail)
            {
              events.$emit('ajax-error', js.code, monospaceText(msg), {
                input: params,
                output: js
              });
            }
            // send message to Slack
            if (!(params.url === '/api/dns/checkdnszone/' && +js.code === 2303)) reportSlack('API request to `' + params.url + '` returned error ' + js.code + '\n```\n' + JSON.stringify(js, null, 2) + '\n```\n', params);
          }
        }
        else if (!('code' in js))
        {
          // assume success - /api/economy/pay/ is missing "code"
          if (hasOkay) params.okay(js, xhr);
        }
      }
      // empty response
      else
      {
        if (hasOkay) params.okay({}, xhr);
        reportSlack('Empty response from `' + params.url + '`', params);
      }
    }
    else
    {
      let api = params.url.match(/^\/api([^?]+)/);
      if (api && api.length > 1) api = api[1];
      else api = 'XYZ';
      if (xhr.status === 401)
      {
        if (params.login && typeof params.login === 'function') params.login(xhr, params); // it will show the Login modal and then retry with "xhr.$perform(xhr, params)"
      }
      else if (xhr.status === 404)
      {
        if (hasFail) params.fail(xhr.status, 'API endpoint for ' + api + ' was not found');
        else if (!skipFail) events.$emit('ajax-error', 404, 'API endpoint for ' + api + ' was not found');
      }
      else
      {
        console.log('XHR ERR: ' + params.method, params.url + ' ==> HTTP Status ' + xhr.status);
        console.log(xhr.responseText);
        if (!params.aborted)
        {
          if (hasFail) params.fail(xhr.status, checkJSON(xhr.responseText));
          else if (!skipFail) events.$emit('ajax-error', xhr.status, checkJSON(xhr.responseText));
          reportSlack('API request to `' + params.url + '` returned HTTP status code ' + xhr.status + '\n```\n' + xhr.responseText + '\n```\n', params);
        }
      }
    }
  };

  xhr.onerror = function ajaxError()
  {
    if (hasSpin) params.spinner(false);
    if (hasFail) params.fail(-2, 'Network error');
    else if (!skipFail) events.$emit('ajax-error', -2, 'Network error');
    reportSlack('Network error from `' + params.url + '`', params);
  };

  // progress
  if (params.progress && typeof params.progress === 'function')
  {
    function progressDownload(e)
    {
      if (e.lengthComputable) params.progress(false, e.loaded, e.total);
    }
    xhr.onprogress = progressDownload;
    xhr.onloadstart = progressDownload;
    xhr.onloadend = progressDownload;
    function progressUpload(e)
    {
      if (e.lengthComputable) params.progress(true, e.loaded, e.total);
    }
    xhr.upload.onprogress = progressUpload;
    xhr.upload.onloadstart = progressUpload;
    xhr.upload.onloadend = progressUpload;
  }

  // must be after event handlers
  xhr.$perform = xhrPerform.bind(xhr);
  xhr.$params = params;
  return xhr.$perform(params);
}

// used by the retry mechanism for 401 errors
// expects THIS to be the XHR object
function xhrPerform(params)
{
  // show spinner
  if (params.spinner && typeof params.spinner === 'function') params.spinner(true);
  if (window.performance && window.performance.mark)
  {
    params.uuid = uuid();
    window.performance.mark('start_' + params.uuid);
  }
  this.open(params.method || 'GET', params.url.indexOf('://') !== -1 ? params.url : (process.env.NODE_ENV !== 'development' ? process.env.VUE_APP_API_URL : '') + params.url, true); // Expecting .env file to specify VUE_APP_API_URL
  if (isObject(params.headers))
  {
    for (const head in params.headers) this.setRequestHeader(head, params.headers[head]);
    if (!('X-Requested-With' in params.headers)) this.setRequestHeader('X-Requested-With', 'XmlHttpRequest'); // Mitigate CSRF attacks
  }
  else if (params.url.indexOf('://') == -1) this.setRequestHeader('X-Requested-With', 'XmlHttpRequest'); // Mitigate CSRF attacks - but try to avoid pre-flight when possible
  this.setRequestHeader('X-SRS-Version', pkg.version);
  // if (process.env.NODE_ENV === 'development') this.setRequestHeader('X-DEBUG', '1'); // allow stack-traces from PHP

  let payload;
  if (params.data instanceof FormData)
  {
    // do not add any header - browser will do it automatically and handle the case of multiple files uploaded
    payload = params.data;
  }
  else if (isObject(params.data) || isArray(params.data))
  {
    this.setRequestHeader('Content-Type', 'application/json');
    try
    {
      payload = JSON.stringify(params.data);
    }
    catch (e)
    {
      console.error('AJAX payload contains circular reference');
      if (params.spinner && typeof params.spinner === 'function') params.spinner(false);
      return false;
    }
  }
  else if (params.data instanceof File)
  {
    this.overrideMimeType(params.data.type);
  }
  else if (typeof params.data !== 'undefined')
  {
    console.error('AJAX payload is not an Object');
    if (params.spinner && typeof params.spinner === 'function') params.spinner(false);
    return false;
  }
  /// else this.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

  // finally send the request
  try
  {
    if (payload !== undefined) this.send(payload);
    else this.send();
  }
  catch (err)
  {
    console.log('XHR exception:', params.method, params.url, '-->', err);
    if (params.spinner && typeof params.spinner === 'function') params.spinner(false);
    if (params.fail && typeof params.fail === 'function') params.fail(599, err);
  }
  xhrList.push(this);
  return this;
}

function checkJSON(js)
{
  let j;
  try
  {
    j = JSON.parse(js);
  }
  catch (err)
  {
    return js;
  }
  return (typeof j === 'object' ? j : js);
}

function monospaceText(txt)
{
  return txt;
  // return '<div style="white-space: pre-wrap;">' + txt.replace(/(?:\r\n|\r|\n)/g, '<br/>') + '</div>';
}

export function reportSlack(txt, param)
{
  if (param.data instanceof FormData)
  {
    let text, tmp;
    txt += '\n```\n';
    for (const pair of param.data.entries())
    {
      if (pair[1] instanceof Blob) text = 'BLOB';
      else if (typeof pair[1] == 'string' && pair[1].substr(0, 1) == '[')
      {
        try
        {
          tmp = JSON.parse(pair[1]);
          text = JSON.stringify(tmp, function cleanJson(key, val)
          {
            return ['password', 'code', 'affiliatetoken'].includes(key) ? '*** HIDDEN ***' : val;
          }, 2);
        }
        catch (e)
        {
          text = pair[1];
        }
      }
      else text = ['password', 'code', 'affiliatetoken'].includes(pair[0]) ? '*** HIDDEN ***' : pair[1];
      txt += pair[0] + ' = ' + text + '\n';
    }
    txt += '\n```';
  }
  const data = new FormData();
  data.append('token', process.env.VUE_APP_SLACK_TOKEN);
  data.append('channel', '#api-changes');
  data.append('text', window.location.host + ' AccID (' + (((param.$root || {}).session || {}).accID || 0) + ') = ' + txt + '\n');
  fetch('https://slack.com/api/chat.postMessage', {
    method: 'POST',
    cache: 'no-cache',
    body: data
  }).catch(() => false);
}
