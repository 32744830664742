<template>
  <div class="flexbox infobox pa-4">
    <div>
      <my-icon :size="32">
        <slot name="icon">
          <icon-info />
        </slot>
      </my-icon>
    </div>
    <div class="pt-1 pl-2">
      <slot />
    </div>
  </div>
</template>

<script>
import iconInfo from '@/assets/img/icon/info.svg';

export default
{
  name: 'InfoBox',
  components:
    {
      iconInfo,
    }
};
</script>

<style lang="scss">
  .infobox
  {
    border-radius: 4px;
    background-color: var(--info_box_bg, $widget_info_background);
  }

  .infobox .i_icon
  {
    color: var(--info_icon_bg, grey);
  }

</style>
