<template>
  <div class="card_panel" :class="{card_panel_scroll: scroll}" v-bind="$attrs" v-on="$listeners">
    <div v-if="$slots.title" class="card_title" :class="titleClass">
      <slot name="title" />
    </div>
    <div v-if="chat" v-chat-scroll class="card_body grow flexbox flex-column" :class="{scroll: scroll}">
      <slot />
    </div>
    <div v-else class="card_body grow flexbox flex-column" :class="{scroll: scroll}">
      <slot />
    </div>
    <div v-if="$slots.footer" class="card_footer" style="flex-shrink: 0;">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
export default
{
  name: 'CardPanel',
  inheritAttrs: false,
  props:
    {
      titleClass:
        {
          // CSS class for the Title
          type: [String, Object, Array],
          default: null
        },
      scroll:
        {
          type: Boolean,
          default: true // eslint-disable-line vue/no-boolean-default
        },
      chat:
        {
          type: Boolean,
          default: false
        }
    }
};
</script>

<style lang="scss">
  .card_panel
  {
    display: flex;
    flex-direction: column;
    background-color: $dialog_bg;
    border: 1px solid $dialog_border;
    border-radius: 4px;
  }

  .card_panel_scroll
  {
    overflow: hidden;
    max-height: 85vh; /* must be the same as in Modal.vue */
  }

  .card_title
  {
    /* background-color: $dialog_title_background; */
    /* color: $dialog_title_color; */
    line-height: 1.5;
    padding: 6px 16px;
    font-size: 18px;
    font-weight: 700;
    border-bottom: 1px solid $dialog_border;
    flex-shrink: 0;
  }

</style>
