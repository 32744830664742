import AsyncFactory from '../async';

export default
[
  {
    path: 'list',
    name: 'premiumDNS',
    component: AsyncFactory(() => import(/* webpackChunkName: "Services" */ '@/views/services/PremiumDNS')),
    meta:
      {
        title: 'services.title',
        localize: true,
        menu: true,
      },
    beforeEnter: (to, from, next) =>
    {
      if (!window.isp) next();
      else if (window.isp.whiteLabel.globals.showMenuServices) next();
      else next(false);
    },
  },
];
