import AsyncFactory from '../async';

export default
[
  {
    path: '',
    name: 'toolsMain',
    component: AsyncFactory(() => import(/* webpackChunkName: "Tools" */ '../views/tools/ToolsMain')),
    meta:
      {
        title: 'header.links.domain_tools',
        localize: true,
        menu: (root) => !!(((root.whiteLabel || {}).globals || {}).menuTools || {}).showMain,
      },
    beforeEnter: (to, from, next) =>
    {
      if (!window.isp) next();
      else if (window.isp.whiteLabel.globals.showMenuTools) next();
      else
      {
        const parent = window.isp.menu('tools');
        const children = (parent.children || []).filter(item => (typeof item.meta.menu === 'function' ? item.meta.menu(window.isp) : item.meta.menu));
        next(children[0] || false);
      }
    },
  },
];
