<template>
  <header>
    <header-top />
    <header-menu />
    <div v-if="isSafari" class="py-2">
      <div class="mx-auto pa-4 safari_warning bold">{{ $t('safari') }}</div>
    </div>
  </header>
</template>

<script>
import headerTop from './HeaderTop';
import headerMenu from './HeaderMenu';

export default
{
  name: 'MainHeader',
  components:
    {
      headerTop,
      headerMenu,
    },
  data()
  {
    return {
      isSafari: false,
    };
  },
  watch:
    {
      '$root.isLogged':
        {
          immediate: true,
          handler(newVal)
          {
            if (!newVal && ['search', 'searchBulk'].includes(this.$route.name))
            {
              this.$root.mustLogin = true;
            }
          }
        }
    },
  mounted()
  {
    this.isSafari = /constructor/i.test(window.HTMLElement) || (function checkSafari(p)
    {
      return p.toString() === '[object SafariRemoteNotification]';
    })(!window.safari || (typeof safari !== 'undefined' && window.safari.pushNotification));
  },
};
</script>

<style lang="scss">
  @import '@/assets/scss/theme/header.scss';

  .main_menu > *
  {
    color: var(--header_bottom_color, $header_color);
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    /* font-size: 14px; */
    min-height: 50px;
  }

  .main_menu .i_icon
  {
    margin-right: 8px;
  }

  .main_menu > a.router-link-active,
  .main_menu > a:hover
  {
    background-color: var(--menu_selected_bg, $main_menu_background);
    color: var(--menu_selected_color, $main_menu_active);
  }

  .main_menu > a.router-link-active
  {
    font-weight: bold;
  }

  .safari_warning
  {
    max-width: calc(90% - 2 * 20px);
    color: #FF4949;
    background-color: #FFEDED;
    border: 1px solid #FFDBDB;
    border-radius: 4px;
  }
</style>
