
    module.exports = {
      functional: true,
      render(_h, _vm) {
        const { _c, _v, data, children = [] } = _vm;

        const {
          class: classNames,
          staticClass,
          style,
          staticStyle,
          attrs = {},
          ...rest
        } = data;

        return _c(
          'svg',
          {
            class: [classNames,staticClass],
            style: [style,staticStyle],
            attrs: Object.assign({"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 969 1000"}, attrs),
            ...rest,
          },
          children.concat([_c('path',{attrs:{"d":"M0 484q0-201 142-342T484 0t343 142 142 342-142 343-343 142-342-142T0 484zm63 16q7 166 125 283 52-37 109-60-43-106-47-223H63zm0-31h187q4-104 37-196-62-25-117-68Q68 318 63 469zm128-287q51 39 108 62 43-101 117-176-131 22-225 114zm20 623q88 76 205 95-64-64-105-148-53 19-100 53zm70-305q4 111 45 213 69-22 143-26V500H281zm0-31h188V312q-78-3-153-29-31 88-35 186zm47-215q69 23 141 27V63h-2q-90 76-139 191zm12 486q49 100 127 166h2V719q-69 4-129 21zm160 166h2q78-66 127-166-61-17-129-21v187zm0-219q74 4 143 26 41-102 45-213H500v187zm0-218h188q-4-98-36-186-74 26-152 29v157zm0-188q72-4 141-27-49-115-139-191h-2v218zm53 619q117-19 205-95-47-34-100-53-41 84-105 148zm0-832q74 75 117 176 57-23 107-62-93-92-224-114zm119 655q57 23 109 60 117-117 125-283H719q-4 117-47 223zm10-450q33 92 37 196h187q-6-151-107-264-55 43-117 68z"}})])
        )
      }
    }
  