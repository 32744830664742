<template>
  <my-modal :value="value">
    <my-card>
      <div class="flexbox justify-between align-center" slot="title">
        {{ $t('api.title') }}
        <dot-btn class="secondary" @click="close">
          <my-icon>
            <icon-times />
          </my-icon>
        </dot-btn>
      </div>
      <div class="pa-3">
        <div class="pb-3">{{ $t('api.failed') }}</div>
        <div class="pb-2">{{ $t('api.subtitle') }}</div>
        <div class="api_details pa-2">{{ details }}</div>
      </div>
      <div class="pa-1 flexbox justify-center" slot="footer">
        <my-button class="secondary" @click="close">{{ $t('buttons.close') }}</my-button>
      </div>
    </my-card>
  </my-modal>
</template>

<script>
import iconTimes from '@/assets/img/icon/times.svg';

export default
{
  name: 'AjaxError',
  components:
    {
      iconTimes,
    },
  props:
    {
      value:
        {
          type: Boolean,
          default: false
        },
      info:
        {
          type: Object,
          default: () => ({})
        }
    },
  data()
  {
    return {
      details: '',
    };
  },
  watch:
    {
      value(newVal)
      {
        if (newVal)
        {
          const input = (this.info || {}).input || {};
          let tmp = 'METHOD = ' + input.method + '\nURL = ' + input.url + '\n';
          if (input.data instanceof FormData)
          {
            let text;
            for (const pair of input.data.entries())
            {
              if (pair[1] instanceof Blob) text = 'BLOB';
              else if (typeof pair[1] == 'string' && pair[1].substr(0, 1) == '[')
              {
                let temp;
                try
                {
                  temp = JSON.parse(pair[1]);
                  text = JSON.stringify(temp, function cleanJson(key, val)
                  {
                    return ['password', 'code', 'affiliatetoken'].includes(key) ? '*** HIDDEN ***' : val;
                  }, 2);
                }
                catch (e)
                {
                  text = pair[1];
                }
              }
              else text = ['password', 'code', 'affiliatetoken'].includes(pair[0]) ? '*** HIDDEN ***' : pair[1];
              tmp += pair[0] + ' = ' + text + '\n';
            }
          }
          this.details = '[COMMAND]\n' + tmp + 'EOF\n\n[RESPONSE]\n' + JSON.stringify((this.info || {}).output || {}, null, 2) + '\nEOF';
        }
        else this.details = '';
      }
    },
  methods:
    {
      close()
      {
        this.$emit('input', false);
      },
    }
};
</script>

<style lang="scss">
  .api_details
  {
    background-color: var(--info_box_bg);
    border-radius: 6px;
    border: 1px solid var(--page_hover_bg);
    white-space: pre-wrap;
  }
</style>
