
    module.exports = {
      functional: true,
      render(_h, _vm) {
        const { _c, _v, data, children = [] } = _vm;

        const {
          class: classNames,
          staticClass,
          style,
          staticStyle,
          attrs = {},
          ...rest
        } = data;

        return _c(
          'svg',
          {
            class: ["sort-down-duotone_svg__svg-inline--fa sort-down-duotone_svg__fa-sort-down sort-down-duotone_svg__fa-w-10",classNames,staticClass],
            style: [style,staticStyle],
            attrs: Object.assign({"aria-hidden":"true","data-prefix":"fad","data-icon":"sort-down","xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 320 512"}, attrs),
            ...rest,
          },
          children.concat([_c('g',{staticClass:"sort-down-duotone_svg__fa-group",attrs:{"fill":"currentColor"}},[_c('path',{staticClass:"sort-down-duotone_svg__fa-secondary",attrs:{"d":"M279.07 224.05h-238c-21.4 0-32.1-25.9-17-41l119-119a23.9 23.9 0 0 1 33.8-.1l.1.1 119.1 119c15.07 15.05 4.4 41-17 41z","opacity":".4"}}),_c('path',{staticClass:"sort-down-duotone_svg__fa-primary",attrs:{"d":"M296.07 329.05 177 448.05a23.9 23.9 0 0 1-33.8.1l-.1-.1-119-119c-15.1-15.1-4.4-41 17-41h238c21.37 0 32.04 25.95 16.97 41z"}})])])
        )
      }
    }
  