export default
{
  googleAnalytic:
    {
      id: 'UA-161712004-1',
      version: '1',
      dimensions:
        {
          TRACKING_VERSION: 'dimension1',
          CLIENT_ID: 'dimension2',
          WINDOW_ID: 'dimension3',
        },
      metrics:
        {
          RESPONSE_END_TIME: 'metric1',
          DOM_LOAD_TIME: 'metric2',
          WINDOW_LOAD_TIME: 'metric3',
        }
    },
  statusInfo: 'https://incidentsystems.statuspage.io/api/v2/summary.json',
  intercom: 'vmglfl0k',
  userSnapKey: '19b40aa4-ff28-4c02-8d31-246dab4ea77f',
  ip_geo: 'ef5f3832baf94b879da62af1cfb304ae', // https://api.ipgeolocation.io/ipgeo?apiKey=
  brand:
    {
      'namesrs.com':
        {
          homepageURL: 'https://www.namesrs.com',
          blogURL: 'https://news.namesrs.com',
          documentationURL: 'http://api3.nameisp.com', // SSL is not yet configured
          brandName: 'Name SRS', // brand name
          companyEmail: 'reseller@namesrs.com',
          companyPhone: '+46 (0) 10-222 86 00',
          pinCode: '0825',
        },
      'nameisp.com':
        {
          homepageURL: 'https://www.nameisp.com',
          blogURL: 'https://news.namesrs.com',
          documentationURL: 'http://api3.nameisp.com', // SSL is not yet configured
          brandName: 'Name ISP', // brand name
          companyEmail: 'support@nameisp.com',
          companyPhone: '+46 (0) 31-301 12 20',
          pinCode: '3764',
        },
    },
  registrantMaxItems: 100, // how many registrants to show in DomainRegistrant.vue
  notifyTimeoutSuccess: 2500, // milliseconds
  notifyTimeoutFail: 5000, // milliseconds
  timezoneOffset: '+02:00', // the API returns all timestamps without a timezone
  ///ajaxTimeout: 30000, // default timeout for AJAX calls in milliseconds
  newVersionPeriod: 150000, // how often to check for a new version
  currencies:
  [
    {
      value: 'EUR',
      text: 'EUR - Euro'
    },
    {
      value: 'SEK',
      text: 'SEK - Swedish Krona'
    },
    {
      value: 'USD',
      text: 'USD - US dollar'
    }
  ],
  paymentMethods:
    {
      Braintree:
        {
          verified: 'https://www.braintreegateway.com/merchants/6t6fvmkpfr83269c/verified'
        },
      SveaWebPay:
        {
          url: ''
        },
      BankGirot:
        {
          color: '#C47D81'
        }
    },
  colors:
    {
      spinner:
        {
          fill: '#1976D2',
          stroke: '#FFFFFF'
        },
      dns:
        {
          default: '',
          A: '#C47D80',
          AAAA: '#AF242A',
          MX: '#87C0EA',
          CNAME: '#ED925F',
          TXT: '#66B163',
          SRV: '#C39C43',
          REDIRECT: '#E683BF',
          MAILFORWARD: '#A084C8',
          CAA: '#E0C96B',
          TLSA: '#888888',
          NAPTR: '#3CB59E',
          NS: '#555555',
        },
      basket: // must be synchronized with locales.json -> checkout.req_types !!!!!!!!!!!
        {
          'create-dns-registration': '#80ABA9',
          'create-domain-escrow': '#777777',
          'create-item-registration': '#777777',
          'create-domain-registration': '#A2895C',
          'create-domain-transfer': '#E57E45',
          'create-ssl': '#A681B4',
          'create-service': '#A681B4',
          'update-dns-renew': '#777777',
          'update-domain-cancellation': '#F56C6C',
          'update-domain-contacts': '#6C899F',
          'update-domain-dns': '#777777',
          'update-domain-registrant': '#638DAF',
          'update-domain-registrant-pre': '#638DAF',
          'update-domain-renew': '#7CB479',
          'update-domain-restore': '#C93244',
          'cancel-service': '#F56C6C',
          default: '#88C0EA',
        }
    },
  dashboard:
    {
      quickLinks:
        {
          files:
          [
            {
              localization: 'dashboard.quick_links.files.description',
              href: 'https://www.nameisp.com/content/downloads/NAME SRS AB_Tjänstebeskrivning_Premium_Anycast_DNS_ver1.3.pdf'
            },
            {
              localization: 'dashboard.quick_links.files.sla',
              href: 'https://www.nameisp.com/content/downloads/Name SRS AB_Serviceavtal (SLA)_ Excedo_Excedo Premium Anycast DNS_ver 1.0.pdf'
            },
            {
              localization: 'dashboard.quick_links.files.terms',
              href: 'https://www.nameisp.com/content/downloads/Name SRS Allmänna villkor 20181128 SV.pdf'
            }
          ]
        }
    },
  tosTrustee: 'https://drive.google.com/file/d/1DLb7XVmW5Gj67F-o-dHH_J-Gt7NEa4QP/view',
  docsGoogle:
    {
      'namesrs.com': 'https://docs.google.com/spreadsheets/d/e/2PACX-1vRpXKwBVJfondAyeFyA3tXZECqeUMbeUEdMckm0LoEJrObHja6ggiz9ZjNnKkOkIVyJXYyXyBiyRJGk/pubhtml?widget=true&headers=false',
      'nameisp.com': 'https://docs.google.com/spreadsheets/d/e/2PACX-1vTHZovLWmgHsT6nQjVGBHZOHnaWu48E6QcEz4ZgqcVzj0FISTNc1Nt3FVO9FNK44G2nGRw2_ueInK88/pubhtml?widget=true&headers=false',
    },
  links:
    {
      'namesrs.com':
        {
          twitter: 'http://twitter.com/nameisp',
          facebook: 'http://www.facebook.com/nameisp',
          faq: 'https://www.nameisp.com/{LOCALE}/customer-service', // ?question=
          about: 'https://www.nameisp.com/{LOCALE}/about/about-us',
          conditions: 'https://namesrs.com/#conditions',
          reseller_en: 'https://drive.google.com/file/d/1RFQxpPdZtb283DseWnKzMFPsVSnUuF8I/view',
          reseller_sv: 'https://drive.google.com/file/d/1TIdevCPKLxehBp8n2_7RTkfYWSkbhKkq/view',
          premiumDNS: 'https://www.nameisp.com/{LOCALE}/domain-services/premium-dns',
          searchDomains: 'https://www.nameisp.com/{LOCALE}/searchdomains', // ?domainname=
          whitelabel: 'https://docs.google.com/document/d/e/2PACX-1vQarqe5YqQIE5FQBRudS0lONmE-t_3UCMAN4AxJPVBJ1R4hN8IBrzDDYyZF69Z6-X-L1KuHu1cYuuBb/pub',
        },
      'nameisp.com':
        {
          twitter: 'http://twitter.com/nameisp',
          facebook: 'http://www.facebook.com/nameisp',
          faq: 'https://www.nameisp.com/{LOCALE}/customer-service', // ?question=
          about: 'https://www.nameisp.com/{LOCALE}/about/about-us',
          conditions: 'https://www.nameisp.com/{LOCALE}/about/conditions',
          premiumDNS: 'https://www.nameisp.com/{LOCALE}/domain-services/premium-dns',
          searchDomains: 'https://www.nameisp.com/{LOCALE}/searchdomains', // ?domainname=
        },
    },
  ourNameservers:
    [
      ['ns1.nameisp.info', 'ns2.nameisp.info'],
      ['ns1.dnshost.net', 'ns2.dnshost.net'],
      ['sto.excedodns.se', 'emea.excedodns.eu', 'global.excedodns.com'], // Premium
      ['ns1.excedodns.net', 'ns2.excedodns.net', 'ns3.excedodns.net', 'ns4.excedodns.net'],
      ['sto.excedodns.se',
        'emea.excedodns.eu',
        'global.excedodns.com',
        'edns1.anycast.iis.se',
        'edns2.anycast.iis.se'],
      ['edns1.anycast.iis.se', 'edns2.anycast.iis.se'],
      ['ns1.abedns.se', 'ns2.abedns.se', 'ns3.abedns.net', 'ns4.abedns.net']
    ],
  euList: [
    'AT',
    'IT',
    'BE',
    'LV',
    'BG',
    'LT',
    'HR',
    'LU',
    'CY',
    'MT',
    'CZ',
    'NL',
    'DK',
    'PL',
    'EE',
    'PT',
    'FI',
    'RO',
    'FR',
    'SK',
    'DE',
    'SI',
    'GR',
    'ES',
    'HU',
    'IE'
  ],
  CaaCertIssuers:
  [
    {
      text: 'AC Camerfirma',
      value: 'camerfirma.com'
    },
    {
      text: 'ACCV (Government of Spain)',
      value: 'accv.es'
    },
    {
      text: 'Actalis',
      value: 'actalis.it"'
    },
    {
      text: 'Amazon',
      value: 'amazon.com'
    },
    {
      text: 'Asseco (Unizeto, Certum)',
      value: 'certum.pl'
    },
    {
      text: 'Buypass',
      value: 'buypass.com'
    },
    {
      text: 'CA Disig',
      value: 'disig.sk'
    },
    {
      text: 'CATCert (Consorci AOC)',
      value: 'aoc.cat'
    },
    {
      text: 'Certinomis (Docapost)',
      value: 'www.certinomis.com'
    },
    {
      text: 'Certizen (Hongkong Post)',
      value: 'hongkongpost.gov.hk'
    },
    {
      text: 'certSIGN',
      value: 'certSIGN'
    },
    {
      text: 'CFCA (China Financial)',
      value: 'cfca.com.cn'
    },
    {
      text: 'Chunghwa Telecom',
      value: 'cht.com.tw'
    },
    {
      text: 'Comodo',
      value: 'comodoca.com'
    },
    {
      text: 'D-TRUST',
      value: 'd-trust.net'
    },
    {
      text: 'DFN-PKI',
      value: 'pki.dfn.de'
    },
    {
      text: 'DigiCert',
      value: 'digicert.com'
    },
    {
      text: 'DocuSign (Keynectis, OpenTrust, Certplus)',
      value: 'docusign.fr'
    },
    {
      text: 'e-tugra',
      value: 'e-tugra.com'
    },
    {
      text: 'EDICOM',
      value: 'edicomgroup.com'
    },
    {
      text: 'Entrust',
      value: 'entrust.net'
    },
    {
      text: 'Firmaprofesional',
      value: 'firmaprofesional.com'
    },
    {
      text: 'FNMT (Government of Spain)',
      value: 'fnmt.es'
    },
    {
      text: 'GlobalSign',
      value: 'globalsign.com'
    },
    {
      text: 'GoDaddy (Starfield Technologies)',
      value: 'godaddy.com'
    },
    {
      text: 'Google Trust Services',
      value: 'pki.goog'
    },
    {
      text: 'GRCA (Government of Taiwan)',
      value: 'gca.nat.gov.tw'
    },
    {
      text: 'HARICA',
      value: 'harica.gr'
    },
    {
      text: 'IdenTrust',
      value: 'identrust.com'
    },
    {
      text: 'Izenpe',
      value: 'izenpe.com'
    },
    {
      text: 'Kamu SM',
      value: 'kamusm.gov.tr'
    },
    {
      text: "Let's Encrypt",
      value: 'letsencrypt.org'
    },
    {
      text: 'Microsec e-Szignó',
      value: 'e-szigno.hu'
    },
    {
      text: 'NetLock',
      value: 'netlock.hu'
    },
    {
      text: 'PKIoverheid',
      value: 'www.pkioverheid.nl'
    },
    {
      text: 'PROCERT',
      value: 'procert.net.ve'
    },
    {
      text: 'QuoVadis',
      value: 'quovadisglobal.com'
    },
    {
      text: 'SECOM',
      value: 'secomtrust.net'
    },
    {
      text: 'SectiGO',
      value: 'sectigo.com'
    },
    {
      text: 'Sertifitseerimiskeskuse',
      value: 'sk.ee'
    },
    {
      text: 'StartCom',
      value: 'startcomca.com'
    },
    {
      text: 'SwissSign',
      value: 'swisssign.com'
    },
    {
      text: 'Symantec (GeoTrust, Thawte, RapidSSL)',
      value: 'symantec.com'
    },
    {
      text: 'T-Systems',
      value: 'telesec.de'
    },
    {
      text: 'Telia',
      value: 'telia.com'
    },
    {
      text: 'Trust Provider B.V. (Networking4all)',
      value: 'trustproviderbv.digitalcertvalidation.com'
    },
    {
      text: 'Trustwave',
      value: 'trustwave.com'
    },
    {
      text: 'Web.com',
      value: 'web.com'
    },
    {
      text: 'WISeKey',
      value: 'wisekey.com'
    },
    {
      text: 'WoSign',
      value: 'wosign.com'
    },
  ],
  secAlgorithm:
    {
      // 1: "RSA/MD5",
      // 2: "Diffie-Hellman",
      3: 'DSA/SHA-1',
      5: 'RSA/SHA-1',
      6: 'DSA/NSEC3-SHA1',
      7: 'RSA/SHA-1-NSEC3-SHA1',
      8: 'RSA/SHA-256',
      10: 'RSA/SHA-512',
      12: 'GOST R 34.10-2001',
      13: 'ECDSA Curve P-256/SHA-256',
      14: 'ECDSA Curve P-384/SHA-384'
    },
  secDigest:
    {
      1: 'SHA-1',
      2: 'SHA-256',
      3: 'GOST R 34.11-94',
      4: 'SHA-384'
    },
  typeBasket:
    {
      'create-domain-registration':
      {
        component: 'domainRegistration',
        icon: 'icon-world',
        price: 'Registration',
        years: 'regY'
      },
      'update-domain-registrant':
      {
        component: 'changeRegistrant',
        icon: 'icon-user',
        price: 'OwnerChange',
      },
      'create-domain-transfer':
      {
        component: 'domainTransfer',
        price: 'Transfer',
        icon: 'icon-exchange',
      },
      'update-domain-renew':
      {
        component: 'domainRenew',
        icon: 'icon-reload',
        price: 'Renew',
        years: 'renewY'
      },
      'update-domain-restore':
      {
        component: 'domainRenew',
        icon: 'icon-reload',
        price: 'Restore',
        years: 'renewY'
      },
      'update-domain-contacts':
      {
        component: 'updateContacts',
        icon: 'icon-users',
      },
      'update-domain-dns':
      {
        component: 'changeDns',
        icon: 'icon-globe',
      },
      'update-domain-cancellation':
        {
          component: 'createDns',
          icon: 'icon-domain-cancel',
        },
      'create-ssl':
        {
          component: 'createSsl',
          icon: 'icon-security',
          price: 'Create'
        },
      'create-service':
        {
          component: 'createService',
          icon: 'icon-security',
          price: 'Create'
        },
      'create-dns-registration':
        {
          component: 'createDns',
          icon: 'icon-dns',
          price: 'Registration'
        },
      'cancel-service':
        {
          component: 'createDns',
          icon: 'icon-domain-cancel',
        },
      standard:
        {
          component: 'standardItem',
          title: 'Item',
          icon: 'icon-flash',
        }
    }
};
