
    module.exports = {
      functional: true,
      render(_h, _vm) {
        const { _c, _v, data, children = [] } = _vm;

        const {
          class: classNames,
          staticClass,
          style,
          staticStyle,
          attrs = {},
          ...rest
        } = data;

        return _c(
          'svg',
          {
            class: [classNames,staticClass],
            style: [style,staticStyle],
            attrs: Object.assign({"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 1000 1000"}, attrs),
            ...rest,
          },
          children.concat([_c('path',{attrs:{"d":"M494 375h6c68 0 125 57 125 125v8zm-179 33c-14 27-24 59-24 92 0 115 94 209 209 209 33 0 65-10 92-24l-65-64c-7 2-17 4-27 4-68 0-125-57-125-125 0-10 2-20 4-27zM84 178l53-53 738 738-53 53c-47-46-94-92-140-139-57 24-117 35-182 35-209 0-387-128-459-312 33-82 88-152 156-207-38-38-75-77-113-115zm416 113c-27 0-53 6-76 16l-90-90c51-20 107-30 166-30 209 0 385 129 457 313-31 78-80 144-142 197L693 576c10-23 16-49 16-76 0-115-94-209-209-209z"}})])
        )
      }
    }
  