
    module.exports = {
      functional: true,
      render(_h, _vm) {
        const { _c, _v, data, children = [] } = _vm;

        const {
          class: classNames,
          staticClass,
          style,
          staticStyle,
          attrs = {},
          ...rest
        } = data;

        return _c(
          'svg',
          {
            class: [classNames,staticClass],
            style: [style,staticStyle],
            attrs: Object.assign({"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 785.7 1000"}, attrs),
            ...rest,
          },
          children.concat([_c('path',{attrs:{"d":"M786 692q0 15-6 39t-12 38q-11 28-68 60-52 28-103 28-15 0-30-2t-32-7-26-8-31-11-28-10q-54-20-97-47-71-44-148-120T85 504q-27-43-46-97-2-5-10-28t-12-31-8-26-7-32-2-29q0-52 29-104 31-57 59-68 14-6 38-12t39-6q8 0 12 2 10 3 30 42 6 11 16 31t20 35 17 30q2 2 10 14t12 20 4 16q0 11-16 27t-35 31-34 30-16 25q0 5 3 13t4 11 8 14 7 10q42 77 97 132t131 97q1 0 10 6t14 8 11 5 13 2q10 0 25-16t30-34 31-35 28-16q7 0 15 4t20 12 14 10q14 8 30 17t36 20 30 17q39 19 42 29 2 4 2 12z"}})])
        )
      }
    }
  