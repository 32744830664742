import AsyncFactory from '../async';

export default
[
  {
    path: 'profile',
    name: 'userProfile',
    component: AsyncFactory(() => import(/* webpackChunkName: "User" */ '@/views/user/ProfileInfo')),
    meta:
      {
        title: 'user.profile.title',
        localize: true,
        menu: true,
      }
  },
  {
    path: 'security',
    name: 'userSecurity',
    component: AsyncFactory(() => import(/* webpackChunkName: "User" */ '@/views/user/ChangePassword')),
    meta:
      {
        title: 'user.security.title',
        localize: true,
        menu: true,
      }
  },
  {
    path: 'settings',
    name: 'userSettings',
    component: AsyncFactory(() => import(/* webpackChunkName: "User" */ '@/views/user/UserSettings')),
    meta:
      {
        title: 'user.settings.title',
        localize: true,
        menu: true,
      }
  },
];
