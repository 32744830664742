import Vue from 'vue';
import { VTooltip, VPopover, VClosePopover } from 'v-tooltip';
import { ObserveVisibility } from 'vue-observe-visibility';
import SNotify from 'vue-snotify';
import myCard from '@/components/dialogs/CardPanel';
import formField from '@/components/ui/FormField';
import formValidator from '@/components/ui/FormValidator.vue';
import Icon from '@/components/ui/MyIcon';
import modal from '@/components/dialogs/MyModal';
import dropDown from '@/components/ui/DropDown';
import flatButton from '@/components/buttons/NormalButton';
import iconButton from '@/components/buttons/IconButton';
import myButton from '@/components/buttons/ColorButton';
import switcher from '@/components/ui/SwitchComp';

Vue.use(SNotify, {
  toast: {
    titleMaxLength: 30,
    bodyMaxLength: 250,
  },
});

/* eslint-disable vue/component-definition-name-casing */
Vue.directive('tooltip', VTooltip);
Vue.directive('close-popover', VClosePopover);
Vue.directive('observe-visibility', ObserveVisibility);
Vue.component('pop-over', VPopover);
Vue.component('my-card', myCard);
Vue.component('form-field', formField);
Vue.component('form-validator', formValidator);
Vue.component('my-icon', Icon);
Vue.component('my-modal', modal);
Vue.component('drop-down', dropDown);
Vue.component('flat-button', flatButton);
Vue.component('my-button', myButton);
Vue.component('dot-btn', iconButton);
Vue.component('my-switch', switcher);
