
    module.exports = {
      functional: true,
      render(_h, _vm) {
        const { _c, _v, data, children = [] } = _vm;

        const {
          class: classNames,
          staticClass,
          style,
          staticStyle,
          attrs = {},
          ...rest
        } = data;

        return _c(
          'svg',
          {
            class: [classNames,staticClass],
            style: [style,staticStyle],
            attrs: Object.assign({"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 1000 1000"}, attrs),
            ...rest,
          },
          children.concat([_c('path',{attrs:{"d":"M397 584c103 0 187-84 187-188s-84-187-187-187-188 84-188 187 84 188 188 188zm250 0 207 207-63 63-207-208v-33l-12-11c-47 41-109 64-175 64-151 0-272-119-272-270s121-271 272-271 269 121 269 271c0 67-23 129-64 176l11 12h34z"}})])
        )
      }
    }
  