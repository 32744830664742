function alpha(c)
{ // char code of alphanumeric encoding
  return '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ $%*+-./:'.indexOf(String.fromCharCode(c));
}

export default function quickresponse(text, level, ver)
{
  // create QR and micro QR bar code symbol
  let mode, size, align, blk, ec, i, j, k, k1, c, d, w, x, y, el;
  const enc = [0];
  let eb = 0; // encoding data, length, bits
  const erc = [ // error correction words L,M,Q,H, blocks L,M,Q,H
    [2, 99, 99, 99, 1, 1, 1, 1], // micro QR version M1 (99=N/A)
    [5, 6, 99, 99, 1, 1, 1, 1], // M2
    [6, 8, 99, 99, 1, 1, 1, 1], // M3
    [8, 10, 14, 99, 1, 1, 1, 1], // M4
    [7, 10, 13, 17, 1, 1, 1, 1], // QR version 1
    [10, 16, 22, 28, 1, 1, 1, 1], // 2
    [15, 26, 18, 22, 1, 1, 2, 2], // 3
    [20, 18, 26, 16, 1, 2, 2, 4], // 4
    [26, 24, 18, 22, 1, 2, 4, 4], // 5
    [18, 16, 24, 28, 2, 4, 4, 4], // 6
    [20, 18, 18, 26, 2, 4, 6, 5], // 7
    [24, 22, 22, 26, 2, 4, 6, 6], // 8
    [30, 22, 20, 24, 2, 5, 8, 8], // 9
    [18, 26, 24, 28, 4, 5, 8, 8], // 10
    [20, 30, 28, 24, 4, 5, 8, 11], // 11
    [24, 22, 26, 28, 4, 8, 10, 11], // 12
    [26, 22, 24, 22, 4, 9, 12, 16], // 13
    [30, 24, 20, 24, 4, 9, 16, 16], // 14
    [22, 24, 30, 24, 6, 10, 12, 18], // 15
    [24, 28, 24, 30, 6, 10, 17, 16], // 16
    [28, 28, 28, 28, 6, 11, 16, 19], // 17
    [30, 26, 28, 28, 6, 13, 18, 21], // 18
    [28, 26, 26, 26, 7, 14, 21, 25], // 19
    [28, 26, 30, 28, 8, 16, 20, 25], // 20
    [28, 26, 28, 30, 8, 17, 23, 25], // 21
    [28, 28, 30, 24, 9, 17, 23, 34], // 22
    [30, 28, 30, 30, 9, 18, 25, 30], // 23
    [30, 28, 30, 30, 10, 20, 27, 32], // 24
    [26, 28, 30, 30, 12, 21, 29, 35], // 25
    [28, 28, 28, 30, 12, 23, 34, 37], // 26
    [30, 28, 30, 30, 12, 25, 34, 40], // 27
    [30, 28, 30, 30, 13, 26, 35, 42], // 28
    [30, 28, 30, 30, 14, 28, 38, 45], // 29
    [30, 28, 30, 30, 15, 29, 40, 48], // 30
    [30, 28, 30, 30, 16, 31, 43, 51], // 31
    [30, 28, 30, 30, 17, 33, 45, 54], // 32
    [30, 28, 30, 30, 18, 35, 48, 57], // 33
    [30, 28, 30, 30, 19, 37, 51, 60], // 34
    [30, 28, 30, 30, 19, 38, 53, 63], // 35
    [30, 28, 30, 30, 20, 40, 56, 66], // 36
    [30, 28, 30, 30, 21, 43, 59, 70], // 37
    [30, 28, 30, 30, 22, 45, 62, 74], // 38
    [30, 28, 30, 30, 24, 47, 65, 77], // 39
    [30, 28, 30, 30, 25, 49, 68, 81] // 40
  ];

  function push(val, bits)
  { // add data to bit stream
    val <<= 8;
    eb += bits;
    enc[enc.length - 1] |= val >> eb;
    while (eb > 7) enc[enc.length] = (val >> (eb -= 8)) & 255;
  }

  function set(_x, _y, pat)
  { // layout fixed pattern: finder & align
    for (let ii = 0; ii < pat.length; ii++)
    {
      for (let p = pat[ii], jj = 0; 1 << jj <= pat[0]; jj++, p >>= 1)
      {
        mat[_y + ii][_x + jj] = (p & 1) | 2;
      }
    }
  }

  function get(_x, _y, p)
  { // test pattern mask
    let dd = mat[_y][_x];
    if ((dd & 2) === 0) dd ^= pat[p](_x, _y) === 0; // invert only data according mask
    return dd & 1;
  }

  level = 'LMQHlmqh0123'.indexOf(level || 0) & 3; // level "LMQH" to 0,1,2,3
  k1 = ''; // for no kanji.js

  /** data analysis */
  for (mode = i = 0; i < text.length; i++)
  { // compute mode
    c = text.charCodeAt(i);
    if (c >= 48 && c < 58) continue; // numeric
    if (mode === 0) mode = 1; // alphanumeric at least
    if (alpha(c) >= 0) continue;
    if (mode === 1) mode = 2; // binary mode
    if (c >= 32 && c < 127) continue;
    if (k1.indexOf(String.fromCharCode(c), k1.length >> 1) < 0)
    {
      mode = 2;
      break;
    }
    mode = 3; // Kanji mode
  }
  if (mode === 2) text = unescape(encodeURIComponent(text)); // unicode to UTF-8

  /** compute symbol version size, ver < 1: micro QR */
  w = Math.ceil(text.length * [10 / 3, 11 / 2, 8, 13][mode]); // code len:
  // 3 digits in 10 bits, 2 chars in 11 bits, 1 byte, 13 bits/byte
  c = [[10, 12, 14], [9, 11, 13], [8, 16, 16], [8, 10, 12]][mode]; // # of bits of count indicator
  ver = ver === undefined ? 1 : ver < mode - 3 ? mode - 4 : ver - 1;
  do
  { // increase version till message fits
    if (++ver >= erc.length - 3) return []; // text too long for QR
    blk = erc[ver + 3][level + 4]; // # of error correction blocks
    ec = erc[ver + 3][level]; // # of error correction bytes
    size = ver * (ver < 1 ? 2 : 4) + 17; // symbol size
    align = ver < 2 ? 0 : (ver / 7 | 0) + 2; // # of align patterns
    el = (size - 1) * (size - 1) - (5 * align - 1) * (5 * align - 1); // total bits - align - timing
    el -= ver < 1 ? 59 : ver < 2 ? 191 : ver < 7 ? 136 : 172; // finder, version, format
    el = (el >> 3) - ec * blk; // remaining data bytes
    k = ver < 1 ? ver + ((19 - 2 * mode) / 3 | 0) : c[(ver + 7) / 17 | 0]; // count indicator bits
    i = ver < 1 ? ver + (ver & 1) * 4 + 3 : 4; // mode indicator bits, M1+M3: +4 bits
  } while (8 * el < w + i + k); // message fits in version
  w = Math.floor(el / blk); // # of words in group 1 (group 2: w+1)
  const b = blk + w * blk - el; // # of blocks in group 1 (group 2: blk-b)

  /** encode head indicator */
  if (ver > 0) push(1 << mode, 4); // mode indicator, QR
  else push(mode, ver + 3); // mode indicator micro QR
  push(text.length, k); // character count indicator

  /** encode data */
  if (mode === 0)
  { // encode numeric data
    for (i = 0; i < text.length - 2; i += 3)
    {
      push(text.substr(i, 3), 10);
    } // 3 digits in 10 bits
    if (i < text.length) push(text.substring(i), text.length - i === 1 ? 4 : 7);
  }
  else if (mode === 1)
  { // encode alphanumeric data
    for (i = 0; i < text.length - 1; i += 2) // 2 chars in 11 bits
    {
      push(alpha(text.charCodeAt(i)) * 45 + alpha(text.charCodeAt(i + 1)), 11);
    }
    if (i < text.length) push(alpha(text.charCodeAt(i)), 6);
  }
  else if (mode === 2) // encode binary data
  {
    for (i = 0; i < text.length; i++)
    {
      push(text.charCodeAt(i), 8);
    }
  } // 1 char in 8 bits
  else
  {
    for (i = 0; i < text.length; i++)
    { // encode Kanji
      c = k1.indexOf(text.charAt(i), k1.length >> 1) - (k1.length >> 1);
      c = (k1.charCodeAt(c) & 0x3fff) - 320; // shift JIS X 0208
      push((c >> 8) * 192 + (c & 255), 13); // 1 char in 13 bits
    }
  }
  if ((-3 & ver) === -3 && el === enc.length)
  {
    enc[w - 1] >>= 4;
  } // M1, M3: shift high bits to low nibble
  if (el >= enc.length) push(0, ver > 0 ? 4 : ver + 6); // terminator
  if (eb === 0 || el < enc.length) enc.pop(); // bit padding
  for (i = 236; el > enc.length; i ^= 236 ^ 17) // byte padding
  {
    enc.push((-3 & ver) === -3 && enc.length === el - 1 ? 0 : i);
  } // M1, M3: last 4 bit zero

  /** error correction coding */
  const rs = new Array(ec + 1); // reed/solomon code
  const lg = new Array(256);
  const ex = new Array(255); // log/exp table for multiplication
  for (j = 1, i = 0; i < 255; i++)
  { // compute log/exp table of Galois field prime
    ex[i] = j;
    lg[j] = i;
    j += j;
    if (j > 255) j ^= 285; // GF polynomial a^8+a^4+a^3+a^2+1 = 100011101b = 285
  }
  for (i = 0, rs[0] = 1; i < ec; i++) // compute RS generator polynomial
  {
    for (j = i + 1, rs[j] = 0; j > 0; j--)
    {
      rs[j] ^= ex[(lg[rs[j - 1]] + i) % 255];
    }
  }
  for (i = 0; i <= ec * blk; i++) enc.push(0); // clr checkwords
  for (k = c = 0, eb = el; c < blk; c++, eb += ec) // for each data block
  {
    for (i = c < b ? w : w + 1; i-- > 0; k++) // compute RS checkwords
    {
      for (j = 0, x = enc[eb] ^ enc[k]; j++ < ec;)
      {
        enc[eb + j - 1] = enc[eb + j] ^ (x ? ex[(lg[rs[j]] + lg[x]) % 255] : 0);
      }
    }
  }

  /** layout symbol */
  const mat = new Array(size); // bit 2 reserved space
  for (i = 0; i < size; i++) mat[i] = new Array(size); // define matrix
  c = ver < 1 ? 0 : 6;
  for (i = 8; i < size; i++) mat[c][i] = mat[i][c] = i & 1 ^ 3; // timing pattern
  set(0, 0, [383, 321, 349, 349, 349, 321, 383, 256, 511]); // finder upper left +format
  if (ver > 0)
  {
    set(0, size - 8, [256, 383, 321, 349, 349, 349, 321, 383]); // finder lower left
    set(size - 8, 0, [254, 130, 186, 186, 186, 130, 254, 0, 255]); // finder upper right
    c = 2 * Math.floor(2 * (ver + 1) / (1 - align)); // alignment pattern spacing
    for (x = 0; x < align; x++) // alignment grid
    {
      for (y = 0; y < align; y++)
      {
        if ((x > 0 && y > 0) || (x !== y && x + y !== align - 1)) // no align at finder
        {
          set(x === 0 ? 4 : size - 9 + c * (align - 1 - x), // set alignment pattern
            y === 0 ? 4 : size - 9 + c * (align - 1 - y), [31, 17, 21, 17, 31]);
        }
      }
    }
    if (ver > 6) // reserve version area
    {
      for (i = 0; i < 18; i++)
      {
        mat[size + i % 3 - 11][i / 3 | 0] = mat[i / 3 | 0][size + i % 3 - 11] = 2;
      }
    }
  }
  /** layout codewords */
  y = x = size - 1; // start lower right
  for (i = 0; i < eb; i++)
  {
    c = k = 0;
    j = w + 1; // interleave data
    if (i >= el)
    {
      c = k = el;
      j = ec;
    } // interleave checkwords
    else if (i + blk - b >= el) c = k = -b; // interleave group 2 last bytes
    else if (i % blk >= b) c = -b; // interleave group 2
    else j--; // interleave group 1
    c = enc[c + (i - k) % blk * j + ((i - k) / blk | 0)]; // interleave data
    for (j = (-3 & ver) === -3 && i === el - 1 ? 8 : 128; j > 0; j >>= 1)
    { // M1,M3: 4 bit
      if (c & j) mat[y][x] = 1; // layout bit
      k = ver > 0 && x < 6 ? 1 : 0; // skip vertical timing pattern
      do
      {
        if (1 & x-- ^ k)
        { // advance x,y
          if (size - x - k & 2)
          {
            // top turn
            if (y > 0) y--;
            else continue;
          }
          // bottom turn
          else if (y < size - 1) y++;
          else continue;
          x += 2; // no turn
        }
      }
      while (mat[y][x] & 2); // skip reserved area
    }
  }
  /** data masking */
  let pat = [
    function qr1(_x, _y)
    {
      return (_x + _y) & 1;
    }, // pattern generation conditions
    function qr2(_x, _y)
    {
      return _y & 1;
    },
    function qr3(_x, _y)
    {
      return _x % 3;
    },
    function qr4(_x, _y)
    {
      return (_x + _y) % 3;
    },
    function qr5(_x, _y)
    {
      return (_x / 3 + (_y >> 1)) & 1;
    },
    function qr6(_x, _y)
    {
      return ((_x * _y) & 1) + _x * _y % 3;
    },
    function qr7(_x, _y)
    {
      return (_x * _y + _x * _y % 3) & 1;
    },
    function qr8(_x, _y)
    {
      return (_x + _y + _x * _y % 3) & 1;
    }
  ];
  if (ver < 1) pat = [pat[1], pat[4], pat[6], pat[7]]; // mask pattern for micro QR

  let msk = 0;
  let pen = 100000;
  for (let p = 0; p < pat.length; p++)
  { // compute penalty
    if (ver < 1)
    { // penalty micro QR
      for (x = y = i = 1; i < size; i++)
      {
        x -= get(i, size - 1, p);
        y -= get(size - 1, i, p);
      }
      j = x > y ? 16 * x + y : x + 16 * y;
    }
    else
    { // penalty QR
      d = 0;
      k1 = ''; // # of darks, prev line
      for (j = y = 0; y < size; y++)
      { // horizontal
        c = i = 0;
        k = '0000';
        for (x = 0; x < size; x++)
        {
          k += w = get(x, y, p); // horizontal to string
          d += w; // rule 4: count darks
          if (c === w)
          { // same as prev
            i++; // rule 1
            if (x > 0 && k1.substr(x + 3, 2) === c * 11) j += 3; // rule 2: block 2x2
          }
          else
          { // changed
            if (i > 5) j += i - 2; // rule 1: >5 adjacent
            c ^= 1;
            i = 1;
          }
        }
        if (i > 5) j += i - 2; //  rule 1: >5 adjacent
        for (i = -1; (i = k.indexOf('1011101', i + 4)) > 0;) // rule 3: like finder pattern
        {
          if (k.substr(i - 4, 4) === '0000' || (k + '0000').substr(i + 7, 4) === '0000') j += 40;
        }
        k1 = k; // rule 2: remember last line
      }
      for (x = 0; x < size; x++)
      { // vertical
        c = i = 0;
        k = '0000';
        for (y = 0; y < size; y++)
        {
          k += w = get(x, y, p); // vertical to string
          if (c !== w)
          { // changed
            if (i > 5) j += i - 2; // rule 1: >5 adjacent
            c ^= 1;
            i = 1;
          }
          else i++; // rule 1
        }
        if (i > 5) j += i - 2; //  rule 1: >5 adjacent
        for (i = -1; (i = k.indexOf('1011101', i + 4)) > 0;) // rule 3: like finder pattern
        {
          if (k.substr(i - 4, 4) === '0000' || (k + '0000').substr(i + 7, 4) === '0000') j += 40;
        }
      }
      j += Math.floor(Math.abs(10 - 20 * d / (size * size))) * 10; // rule 4: darks
    }
    if (j < pen)
    {
      pen = j;
      msk = p;
    } // take mask of lower penalty
  }
  for (y = 0; y < size; y++) // remove reservation, apply mask
  {
    for (x = 0; x < size; x++)
    {
      mat[y][x] = get(x, y, msk);
    }
  }

  /** format information, code level & mask */
  j = ver === -3 ? msk : ver < 1 ? (2 * ver + level + 5) * 4 + msk : ((5 - level) & 3) * 8 + msk;
  for (k = j *= 1024, i = 4; i >= 0; i--) // BCH error correction: 5 data, 10 error bits
  {
    if (j >= 1024 << i) j ^= 1335 << i;
  } // generator polynomial: x^10+x^8+x^5+x^4+x^2+x+1 = 10100110111b = 1335
  k ^= j ^ (ver < 1 ? 17477 : 21522); // XOR masking
  for (j = 0; j < 15; j++, k >>= 1) // layout format information
  {
    if (ver < 1)
    {
      mat[j < 8 ? j + 1 : 8][j < 8 ? 8 : 15 - j] = k & 1;
    } // micro QR
    else
    {
      mat[8][j < 8 ? size - j - 1 : j === 8 ? 7 : 14 - j] = // QR horizontal
        mat[j < 6 ? j : j < 8 ? j + 1 : size + j - 15][8] = k & 1;
    }
  } // vertical

  /** version information */
  for (k = ver * 4096, i = 5; i >= 0; i--) // BCH error correction: 6 data, 12 error bits
  {
    if (k >= 4096 << i) k ^= 7973 << i;
  } // generator: x^12+x^11+x^10+x^9+x^8+x^5+x^2+1 = 1111100100101b = 7973
  if (ver > 6) // layout version information
  {
    for (k ^= ver * 4096, j = 0; j < 18; j++, k >>= 1)
    {
      mat[size + j % 3 - 11][j / 3 | 0] = mat[j / 3 | 0][size + j % 3 - 11] = k & 1;
    }
  }

  return mat; // QR additionally needs a quiet zone of 4 cells around the symbol!
}
