<template>
  <div class="ml-2 mr-2 pt-2 pb-2">
    <form-field class="flex-auto">
      <my-icon class="ml-2 mr-2" slot="prepend">
        <component :is="searchIcon" />
      </my-icon>
      <!-- <textarea v-if="searchWhere == 'SEARCH_BULK'" ref="search_field" v-model.trim="search" :placeholder="searchPlaceholder" rows="6"/> -->
      <input ref="search_field" v-model.trim="search" :placeholder="searchPlaceholder" :readonly="searchWhere == 'SEARCH_BULK'" @keydown.esc="search = ''" @keydown.enter="makeSearch">
      <template slot="append">
        <dot-btn v-if="search" size="28" @click.native="search = '',$refs.search_field.focus()">
          <my-icon>
            <icon-times />
          </my-icon>
        </dot-btn>
        <flat-button size="28" class="pointer pr-0 ma-0" :disabled="!search" @click.native="makeSearch">
          <my-icon class="mr-2">
            <icon-search />
          </my-icon>
          {{ searchPlaceholder }}
        </flat-button>
        <pop-over>
          <dot-btn size="28" class="secondary">
            <my-icon class="mx-2">
              <caret-down />
            </my-icon>
          </dot-btn>
          <my-card slot="popover">
            <div v-for="(item,index) in searchTypes" :key="index" v-close-popover class="popup_menu_item flexbox align-center pr-2 pt-2 pb-2" @click="setSearchWhere(index)">
              <my-icon :color="searchWhere === index ? '' : 'transparent'">
                <icon-play />
              </my-icon>
              <my-icon class="ml-1 mr-2">
                <component :is="item.icon" />
              </my-icon>
              <div class="flex-auto">{{ item.caption }}</div>
            </div>
          </my-card>
        </pop-over>
      </template>
    </form-field>
    <domain-whois v-model="showWhois" :domain="whoisSearchDomain" />
  </div>
</template>

<script>
import { toASCII } from '@/lib/punycode';
import { ruleDomain } from '@/lib/validations';
import domainWhois from '@/views/domains/manage/DomainWhois';
import iconGlobe from '@/assets/img/icon/globe.svg';
import iconHelp from '@/assets/img/icon/help.svg';
import iconEye from '@/assets/img/icon/eye.svg';
import iconTimes from '@/assets/img/icon/times.svg';
import iconSearch from '@/assets/img/icon/search.svg';
import iconPlay from '@/assets/img/icon/play.svg';
import caretDown from '@/assets/img/icon/caret-down.svg';
import iconWorld from '@/assets/img/icon/world.svg';

export default
{
  name: 'HeaderSearch',
  components:
    {
      domainWhois,
      /* eslint-disable vue/no-unused-components */
      iconGlobe,
      iconHelp,
      iconEye,
      iconTimes,
      iconSearch,
      iconPlay,
      caretDown,
      iconWorld,
    },
  data()
  {
    return {
      search: '',
      searchWhere: 'SEARCH_DOMAINS',
      showWhois: false,
    };
  },
  computed:
    {
      searchTypes()
      {
        return {
          SEARCH_DOMAINS:
            {
              caption: this.$t('header.search.domains'),
              icon: 'iconGlobe',
            },
          SEARCH_BULK:
            {
              caption: this.$t('header.search.bulk_domains'),
              icon: 'iconWorld',
            },
          SEARCH_WHOIS:
            {
              caption: this.$t('header.search.whois'),
              icon: 'iconEye',
            },
          SEARCH_REQS:
            {
              caption: this.$t('header.search.requests'),
              icon: 'iconEye',
            },
          /*
          SEARCH_FAQ:
            {
              caption: this.$t('header.links.faq'),
              icon: 'iconHelp',
            }
            */
        };
      },
      searchIcon()
      {
        return this.searchTypes[this.searchWhere].icon;
      },
      searchPlaceholder()
      {
        return this.searchTypes[this.searchWhere].caption;
      },
      whoisSearchDomain()
      {
        return {
          domainname: toASCII(this.search),
          idndomainname: this.search,
        };
      },
    },
  watch:
    {
      $route:
        {
          immediate: true,
          handler(newVal, oldVal)
          {
            if (newVal.name == 'search')
            {
              this.searchWhere = 'SEARCH_DOMAINS';
              this.search = newVal.query.domainname || '';
            }
            else if (newVal.name == 'searchBulk')
            {
              this.searchWhere = 'SEARCH_BULK';
              //this.search = (newVal.query.domainnames || '').split(/[^a-zA-Z0-9.-]+/).filter(item => !!item.trim()).join("\n") || '';
            }
            else if (oldVal && oldVal.name == 'searchBulk')
            {
              this.searchWhere = 'SEARCH_DOMAINS';
            }
          }
        }
    },
  created()
  {
    this.$root.$on('logged-out', this.reset);
  },
  beforeDestroy()
  {
    this.$root.$off('logged-out', this.reset);
  },
  methods:
    {
      setSearchWhere(where)
      {
        if (where == 'SEARCH_BULK' && this.searchWhere != where && this.$route.name != 'searchBulk')
        {
          this.$router.push({
            name: 'searchBulk',
            query:
              {
                domainnames: this.search || '',
              }
          }).catch(() => true);
          this.search = '';
        }
        else if (where == 'SEARCH_DOMAINS' && this.searchWhere != where && this.$route.name != 'search')
        {
          this.$router.push({
            name: 'search',
            query:
              {
                domainname: this.search || '',
              }
          }).catch(() => true);
        }
        else if (where == 'SEARCH_REQS' && this.searchWhere != where && this.$route.name != 'domainRequests')
        {
          const params = {
            desc: true,
            orderby: 'created',
            limit: this.$root.user ? +this.$root.user.listsize : 10,
          };
          if (/^O\d+$/i.test(this.search)) params.ordernr = this.search;
          else if (/^\d+$/.test(this.search)) params.requestid = this.search;
          else params.domainname = encodeURIComponent(this.search);
          this.$router.push({
            name: 'domainRequests',
            query: params,
          }).catch(() => true);
        }
        this.searchWhere = where;
        this.$nextTick(() =>
        {
          this.$refs.search_field.focus();
        });
      },
      makeSearch()
      {
        const names = (this.search || '').trim();
        if (!names) return;
        const refresh = this.$route.name === 'search';
        const refreshBulk = this.$route.name === 'searchBulk';
        const refreshReqs = this.$route.name === 'domainRequests';
        const params = {
          desc: true,
          orderby: 'created',
          limit: this.$root.user ? +this.$root.user.listsize : 10,
        };
        switch (this.searchWhere)
        {
          case 'SEARCH_DOMAINS':
            if (this.$route.query.domainname != names)
            {
              if (names.split(/[,\s]/).length > 1)
              {
                this.setSearchWhere('SEARCH_BULK');
                return;
              }
              this.$router.push({
                name: 'search',
                query:
                {
                  domainname: names,
                }
              }).catch(() => true);
            }
            if (refresh) this.$root.refresh++;
            break;
          case 'SEARCH_WHOIS':
            if (ruleDomain(toASCII(names)) === true) this.showWhois = true;
            break;
          case 'SEARCH_FAQ':
            window.location.href = this.$root.siteLink('faq') + '?question=' + encodeURIComponent(names);
            break;
          case 'SEARCH_BULK':
            if (this.$route.query.domainnames != names)
            {
              this.$router.push({
                name: 'searchBulk',
                query:
                {
                  domainnames: names,
                }
              }).catch(() => true);
            }
            if (refreshBulk) this.$root.refresh++;
            break;
          case 'SEARCH_REQS':
            if (/^O\d+$/i.test(names))
            {
              // order
              if (this.$route.query.ordernr != names)
              {
                params.ordernr = names;
                this.$router.push({
                  name: 'domainRequests',
                  query: params,
                }).catch(() => true);
              }
            }
            else if (/^\d+$/.test(names))
            {
              // request
              if (this.$route.query.requestid != names)
              {
                params.requestid = names;
                this.$router.push({
                  name: 'domainRequests',
                  query: params,
                }).catch(() => true);
              }
            }
            else if (names)
            {
              if (this.$route.query.domainname != names)
              {
                params.domainname = encodeURIComponent(names);
                this.$router.push({
                  name: 'domainRequests',
                  query: params,
                }).catch(() => true);
              }
            }
            if (refreshReqs) this.$root.refresh++;
            break;
        }
      },
      reset()
      {
        this.searchWhere = 'SEARCH_DOMAINS';
        this.search = '';
      },
    }
};
</script>
