<template>
  <!-- can not use v-model directly here - it will mutate the VALUE prop and get out of sync with the parent -->
  <my-modal :value="value" @input="close">
    <my-card v-loading="loading > 0" style="min-width: 500px; max-width: 800px;">
      <div class="flexbox justify-between align-center" slot="title">
        {{ $t('domains.details.whois') }} &mdash; {{ domain.idndomainname || domain.domainname }} {{ domain.idndomainname !== domain.domainname ? '(' + domain.domainname + ')' : '' }}
        <dot-btn class="secondary" @click="close">
          <my-icon>
            <icon-times />
          </my-icon>
        </dot-btn>
      </div>
      <div class="pa-2">
        <!-- eslint-disable-next-line -->
        <div v-html="whois"/>
      </div>
      <!--
      <div slot="footer" class="pa-1 flexbox justify-center">
        <my-button class="secondary" @click="close">{{ $t('buttons.close') }}</my-button>
      </div>
      -->
    </my-card>
  </my-modal>
</template>

<script>
import { nl2br } from '@/lib/util';
import iconTimes from '@/assets/img/icon/times.svg';

export default
{
  name: 'DomainWhois',
  components:
  {
    iconTimes,
  },
  props:
    {
      value:
        {
          type: Boolean,
          default: false
        },
      domain:
        {
          type: Object,
          required: true
        }
    },
  data()
  {
    return {
      whois: '',
      loading: 0,
    };
  },
  watch:
    {
      value(newVal)
      {
        if (newVal)
        {
          this.whois = '';
          this.$nextTick(this.fetchData);
        }
      }
    },
  methods:
    {
      fetchData()
      {
        const data = new FormData();
        data.append('domainname', this.domain.domainname);
        this.$ajax(
          {
            method: 'POST',
            url: '/api/domain/whois/',
            login: this.$root.login,
            data,
            okay: (result) =>
            {
              if (result.parameters)
              {
                this.whois = nl2br(result.parameters.whois);
              }
              else
              {
                this.$root.showFailed('domains.details.whois_error');
              }
            },
            spinner: (show) =>
            {
              this.loading += show ? 1 : this.loading > 0 ? -1 : 0;
            }
          }
        );
      },
      close()
      {
        this.$emit('input', false);
      }
    }
};
</script>
