<template>
  <div v-bind="$attrs" class="i_icon" :style="iconStyles" v-on="$listeners">
    <slot />
  </div>
</template>

<script>
export default
{
  name: 'MyIcon',
  inheritAttrs: false,
  props:
    {
      size:
        {
          type: [String, Number],
          default: 13
        },
      color:
        {
          type: String,
          default: ''
        }
    },
  computed:
    {
      iconStyles()
      {
        const size = (typeof this.size === 'number' || (typeof this.size === 'string' && /^\d+(\.\d+)?$/.test(this.size))) ? this.size + 'px' : this.size;
        return {
          color: this.color || 'inherit',
          width: size,
          height: size,
          minWidth: size,
          minHeight: size,
        };
      }
    }
};
</script>

<style lang="scss">
  .i_icon
  {
    display: inline-flex;
    vertical-align: middle;
    margin: auto;
  }

  .i_icon svg
  {
    width: 100%;
    height: 100%;
  }

  .disabled .i_icon svg
  {
    color: #444;
  }
</style>
