import { isURL } from 'validator';

const ipNumber1 = '(?:0{0,2}[1-9]|0?[1-9]\\d|1\\d\\d|2[0-4]\\d|25[0-5])';
const ipNumber2 = '(?:0{0,2}[0-9]|0?[1-9]\\d|1\\d\\d|2[0-4]\\d|25[0-5])';
// const mask = '(?:0?[1-9]|[1-2][0-9]|3[0-2])';

export function validURL(value)
{
  return value ? (isURL(value) ? '' : window.i18n.t('rules.invalid_url')) : '';
}

export function validEmail(value)
{
  if (!value) return '';
  // return /^([a-zA-Z0-9]+\.|[a-zA-Z0-9]+[_-]+[a-zA-Z0-9]+\.)*([a-zA-Z0-9]+|[a-zA-Z0-9]+[_-]+[a-zA-Z0-9]+)@(([a-zA-Z0-9]+|[a-zA-Z0-9]+[-]+[a-zA-Z0-9]+)\.)+[a-zA-Z]{2,}$/.test(value) || window.i18n.t('rules.invalid_email');
  // The following pattern is used to check if the entered e-mail address fits the user@domain format.  It also is used to separate the username from the domain.
  const emailPat = /^(.+)@(.+)$/;

  // The following string represents the pattern for matching all special characters.  We don't want to allow special characters in the address. These characters include ( ) < > @ , ; : \ " . [ ]

  const specialChars = '\\(\\)><@,;:\\\\\\"\\.\\[\\]';

  // The following string represents the range of characters allowed in a username or domainname.  It really states which chars aren't allowed.

  const validChars = '[^\\s' + specialChars + ']';

  // The following pattern applies if the "user" is a quoted string (in which case, there are no rules about which characters are allowed and which aren't; anything goes).  E.g. "jiminy cricket"@disney.com is a legal e-mail address.

  const quotedUser = '("[^"]*")';

  // The following pattern applies for domains that are IP addresses, rather than symbolic names.  E.g. joe@[123.124.233.4] is a legal e-mail address. NOTE: The square brackets are required.

  const ipDomainPat = /^\[(\d{1,3})\.(\d{1,3})\.(\d{1,3})\.(\d{1,3})]$/;

  // The following string represents an atom (basically a series of non-special characters.)

  const atom = validChars + '+';

  // The following string represents one word in the typical username. For example, in john.doe@somewhere.com, john and doe are words. Basically, a word is either an atom or quoted string.

  const word = '(' + atom + '|' + quotedUser + ')';

  // The following pattern describes the structure of the user

  const userPat = new RegExp('^' + word + '(\\.' + word + ')*$');

  // The following pattern describes the structure of a normal symbolic domain, as opposed to ipDomainPat, shown above.

  // const domainPat = new RegExp('^' + atom + '(\\.' + atom + ')*$');

  // Finally, let's start trying to figure out if the supplied address is valid.

  // Begin with the coarse pattern to simply break up user@domain into different pieces that are easy to analyze.

  const matchArray = value.match(emailPat);

  if (matchArray == null)
  {
    // Too many/few @'s or something; basically, this address doesn't even fit the general mould of a valid e-mail address.

    return window.i18n.t('rules.invalid_email'); // "Email address seems incorrect (check @ and .'s)";
  }
  const user = matchArray[1];
  const domain = matchArray[2];

  // Start by checking that only basic ASCII characters are in the strings (0-127).

  for (let i = 0; i < user.length; i++)
  {
    if (user.charCodeAt(i) > 127)
    {
      return window.i18n.t('rules.invalid_email'); // 'This username in email contains invalid characters.';
    }
  }
  for (let i = 0; i < domain.length; i++)
  {
    if (domain.charCodeAt(i) > 127)
    {
      return window.i18n.t('rules.invalid_email'); // 'This domain name contains invalid characters.';
    }
  }

  // See if "user" is valid

  if (user.match(userPat) == null)
  {
    // user is not valid
    return window.i18n.t('rules.invalid_email'); // "The username in email doesn't seem to be valid.";
  }

  // if the e-mail address is at an IP address (as opposed to a symbolic host name) make sure the IP address is valid.

  const IPArray = domain.match(ipDomainPat);
  if (IPArray != null)
  {
    // this is an IP address

    for (let i = 1; i <= 4; i++)
    {
      if (IPArray[i] > 255)
      {
        return window.i18n.t('rules.invalid_email'); // 'Destination IP address is invalid!';
      }
    }
    return '';
  }

  // Domain is symbolic name.  Check if it's valid.

  const atomPat = new RegExp('^' + atom + '$');
  const domArr = domain.split('.');
  const len = domArr.length;
  for (let i = 0; i < len; i++)
  {
    if (domArr[i].search(atomPat) === -1)
    {
      return window.i18n.t('rules.invalid_email'); // 'The domain name does not seem to be valid.';
    }
  }

  // Make sure there's a host name preceding the domain.

  if (len < 2)
  {
    return window.i18n.t('rules.invalid_email'); // 'This email address is missing a hostname!';
  }

  // If we've gotten this far, everything's valid!
  return '';
}

export function ruleRequired(value)
{
  return !!value || window.i18n.t('rules.required_field');
}

export function ruleDomain(value)
{
  const patternIP = new RegExp('^' + ipNumber1 + '\\.' + ipNumber2 + '\\.' + ipNumber2 + '\\.' + ipNumber2 + '$');
  const pattern = /^[a-z\d]([a-z\d-]{0,61}[a-z\d])?(\.[a-z\d]([a-z\d-]{0,61}[a-z\d])?){1,2}$/i;
  if (value && value.length > 255) return window.i18n.t('rules.too_long_domain');
  return value
    ? (pattern.test(value)
        ? (patternIP.test(value)
            ? window.i18n.t('rules.invalid_domain')
            : true
          )
        : window.i18n.t('rules.invalid_domain')
      )
    : true;
}

export function ruleDomainVerb(value)
{
  const pattern = /^[a-z\d]([a-z\d-]{0,61}[a-z\d])?(\.[a-z\d]([a-z\d-]{0,61}[a-z\d])?)?$/i;
  if (value && value.length > 255) return window.i18n.t('rules.too_long_domain');
  return value ? (pattern.test(value) ? true : window.i18n.t('rules.invalid_domain')) : true;
}

export function validHostname(value)
{
  const patternIP = new RegExp('^' + ipNumber1 + '\\.' + ipNumber2 + '\\.' + ipNumber2 + '\\.' + ipNumber2 + '$');
  const pattern = /^([a-z\d]([a-z\d-]{0,61}[a-z\d])?\.){2,}[a-z\d]([a-z\d-]{0,61}[a-z\d])+$/i;
  if (value && value.length > 255) return window.i18n.t('rules.too_long_hostname');
  return value ? (pattern.test(value) ? (patternIP.test(value) ? window.i18n.t('rules.invalid_hostname') : true) : window.i18n.t('rules.invalid_hostname')) : true;
}

export function validIP(value)
{
  const patternIP = new RegExp('^' + ipNumber1 + '\\.' + ipNumber2 + '\\.' + ipNumber2 + '\\.' + ipNumber2 + '$');
  return value ? patternIP.test(value) || window.i18n.t('rules.invalid_ip') : true;
}

export function validPassword(value)
{
  return /^[!-~]{8,}$/.test(value) || window.i18n.t('rules.invalid_password');
}
