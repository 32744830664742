<template>
  <button v-bind="$attrs" class="default_button" :class="{disabled: disabled}" :type="type" :disabled="disabled" v-on="$listeners">
    <slot />
  </button>
</template>

<script>
export default
{
  name: 'NormalButton',
  inheritAttrs: false,
  props:
    {
      disabled:
        {
          type: Boolean,
          default: false
        }
    },
  computed:
    {
      type()
      {
        return this.$attrs.type || 'button';
      }
    }
};
</script>

<style lang="scss">
  .default_button,
  a.default_button
  {
    border: none;
    border-radius: 4px;
    background-color: transparent;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    margin: 5px;
    font-family: inherit;
    font-size: 13px;
    text-decoration: none;
    cursor: pointer;
    position: relative;
    transition: all 0.2s ease-in;
  }

  .default_button.dark
  {
    color: white;
  }

  .default_button:hover
  {
    background-color: $button_default_hover;
  }

  .default_button.dark:hover
  {
    color: black;
  }

  .default_button:disabled
  {
    opacity: 0.4;
    cursor: not-allowed;
  }

  .default_button:active,
  a.default_button:active
  {
    transform: translate(1px, 1px);
  }

  .default_button.primary
  {
    border-color: var(--btn_primary_border, $primary_color) !important;
    background-color: var(--btn_primary_bg, $primary_color);
    color: var(--btn_primary_color, text-contrast($primary_color));
  }

  .default_button.secondary
  {
    background-color: var(--btn_secondary_bg, white);
    border: 1px solid var(--btn_secondary_border, $primary_color) !important;
    color: var(--btn_secondary_color, $primary_color);
  }

  .secondary:hover
  {
    background: var(--btn_secondary_hover_bg, #E5E5E5);
  }

  .primary.default_button
  {
    &:hover
    {
      background-color: var(--btn_primary_hover_bg, #267CD4);
      transition: all 0.2s ease-out;

      &::before
      {
        animation: shine 0.5s 0s linear;
      }
    }

    &::before
    {
      content: '';
      display: block;
      width: 0;
      height: 86%;
      position: absolute;
      top: 7%;
      left: 0;
      opacity: 0;
      background: var(--btn_primary_color, text-contrast(#267CD4));
      box-shadow: 0 0 15px 3px var(--btn_primary_color, text-contrast(#267CD4));
      transform: skewX(-20deg);
    }
  }

  @keyframes shine
  {
    from
    {
      opacity: 0;
      left: 0;
    }

    50%
    {
      opacity: 1;
    }

    to
    {
      opacity: 0;
      left: 100%;
    }
  }

  button.primary:disabled .i_icon svg,
  button.secondary:disabled .i_icon svg
  {
    color: currentColor;
  }

</style>
