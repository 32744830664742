import Vue from 'vue';
// import config from '@/config'

Vue.directive('loading',
  {
    bind(el, binding)
    {
      // console.log('Binding to', el);
      if (el.loadingBox)
      {
        // console.log('There was loadingBox');
        el.removeChild(el.loadingBox);
        if (el.static) el.style.removeProperty('position');
      }
      el.static = false;
      el.loadingBox = null;
    },
    update(el, binding)
    {
      if (!!binding.value === !!binding.oldValue)
      {
        // console.log('Update with the same value', binding.value, el);
        // return;
      }
      if (binding.value && !el.loadingBox)
      {
        // console.log('Showing', el);
        // show
        const position = window.getComputedStyle(el).position;

        if (position === 'static' || position === '')
        {
          el.static = true;
          el.style.position = 'relative';
        }

        const box = document.createElement('div');
        box.className = 'vue-loading';
        box.innerHTML = `<svg width="30" height="30" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <g fill="var(--spinner_fill, #1976D2)">
          <path d="M108.92 355.08a48 48 0 1 0 48 48 48 48 0 0 0-48-48zM256 416a48 48 0 1 0 48 48 48 48 0 0 0-48-48zm208-208a48 48 0 1 0 48 48 48 48 0 0 0-48-48zm-60.92 147.08a48 48 0 1 0 48 48 48 48 0 0 0-48-48zm0-198.16a48 48 0 1 0-48-48 48 48 0 0 0 48 48z" opacity="0.4" />
          <path d="M108.92 60.92a48 48 0 1 0 48 48 48 48 0 0 0-48-48zM48 208a48 48 0 1 0 48 48 48 48 0 0 0-48-48zM256 0a48 48 0 1 0 48 48 48 48 0 0 0-48-48z" />
          </g>
          </svg>`;
        /*
        box.innerHTML = `<svg width="48" height="48" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
          <g fill="${config.colors.spinner.fill || '#1976D2'}" stroke="${config.colors.spinner.stroke || 'none'}" stroke-width="1">
          <rect x="46.5" y="40" width="7" height="20" rx="5" ry="5" transform="rotate(0 50 50) translate(0 -30)"/>
          <rect x="46.5" y="40" width="7" height="20" rx="5" ry="5" transform="rotate(30 50 50) translate(0 -30)"/>
          <rect x="46.5" y="40" width="7" height="20" rx="5" ry="5" transform="rotate(60 50 50) translate(0 -30)"/>
          <rect x="46.5" y="40" width="7" height="20" rx="5" ry="5" transform="rotate(90 50 50) translate(0 -30)"/>
          <rect x="46.5" y="40" width="7" height="20" rx="5" ry="5" transform="rotate(120 50 50) translate(0 -30)"/>
          <rect x="46.5" y="40" width="7" height="20" rx="5" ry="5" transform="rotate(150 50 50) translate(0 -30)"/>
          <rect x="46.5" y="40" width="7" height="20" rx="5" ry="5" transform="rotate(180 50 50) translate(0 -30)"/>
          <rect x="46.5" y="40" width="7" height="20" rx="5" ry="5" transform="rotate(210 50 50) translate(0 -30)"/>
          <rect x="46.5" y="40" width="7" height="20" rx="5" ry="5" transform="rotate(240 50 50) translate(0 -30)"/>
          <rect x="46.5" y="40" width="7" height="20" rx="5" ry="5" transform="rotate(270 50 50) translate(0 -30)"/>
          <rect x="46.5" y="40" width="7" height="20" rx="5" ry="5" transform="rotate(300 50 50) translate(0 -30)"/>
          <rect x="46.5" y="40" width="7" height="20" rx="5" ry="5" transform="rotate(330 50 50) translate(0 -30)"/>
          </g>
          </svg>`;
        */
        el.appendChild(box);
        el.loadingBox = box;
      }
      else if (!binding.value)
      {
        // console.log('Hiding', el);
        // hide
        if (!el.loadingBox)
        {
          // console.log('There was no loadingBox');
          return;
        }
        // there is an issue with the transition timing - probably Vuetify transitions of the dialog finishes before our transition
        // and thus our event handler onTransitionEnd is probably not called and we end up with an uncleared loadingBox
        if (el === el.loadingBox.parentElement) el.removeChild(el.loadingBox);
        el.loadingBox = null;
        if (el.static) el.style.removeProperty('position');
      }
    },
  });

// auto-focus certain form field
Vue.directive('focus',
  {
    inserted: function(el)
    {
      Vue.nextTick(() =>
      {
        el.focus();
      });
    }
  });

// wrapper for ResizeObserver
Vue.directive('resize',
  {
    bind(el, binding)
    {
      if (window.ResizeObserver && typeof binding.value === 'function')
      {
        el.__resizeObserver__ = new window.ResizeObserver(entries =>
        {
          binding.value(entries[0].contentRect);
        });
        el.__resizeObserver__.observe(el);
      }
    },
    unbind(el)
    {
      if (el.__resizeObserver__) el.__resizeObserver__.disconnect();
    }
  });

// Monitors an element and scrolls to the bottom if a new child is added (always:false = prevent scrolling if user manually scrolled up)
// <ul class="messages" v-chat-scroll="{always: false}">
//   <li class="message" v-for="n in messages">{{ n }}</li>
// </ul>
Vue.directive('chat-scroll',
  {
    bind(el, binding)
    {
      let timeout;
      let scrolled = false;

      el.addEventListener('scroll', function onScroll(e)
      {
        if (timeout) window.clearTimeout(timeout);
        timeout = window.setTimeout(() =>
        {
          scrolled = el.scrollTop + el.clientHeight + 1 < el.scrollHeight;
        }, 200);
      });

      (new MutationObserver(function onMutation(e)
      {
        // scroll to bottom when new child was inserted/deleted from the container
        const config = binding.value || {};
        const pause = config.always === false && scrolled;
        if (pause || e[e.length - 1].addedNodes.length != 1) return;
        el.scrollTop = el.scrollHeight;
      })).observe(el, { childList: true });

      if (window.ResizeObserver)
      {
        // scroll to bottom when container changes its dimensions
        el.__resizeObserver__ = new window.ResizeObserver(entries =>
        {
          const config = binding.value || {};
          const pause = config.always === false && scrolled;
          if (!pause) el.scrollTop = el.scrollHeight;
        });
        el.__resizeObserver__.observe(el);
      }
    },
    inserted(el)
    {
      el.scrollTop = el.scrollHeight;
    },
    unbind(el)
    {
      if (el.__resizeObserver__) el.__resizeObserver__.disconnect();
    }
  });
