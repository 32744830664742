
    module.exports = {
      functional: true,
      render(_h, _vm) {
        const { _c, _v, data, children = [] } = _vm;

        const {
          class: classNames,
          staticClass,
          style,
          staticStyle,
          attrs = {},
          ...rest
        } = data;

        return _c(
          'svg',
          {
            class: [classNames,staticClass],
            style: [style,staticStyle],
            attrs: Object.assign({"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 1000 1000"}, attrs),
            ...rest,
          },
          children.concat([_c('path',{attrs:{"d":"M500 375c68 0 125 57 125 125s-57 125-125 125-125-57-125-125 57-125 125-125zm0 334c115 0 209-94 209-209s-94-209-209-209-209 94-209 209 94 209 209 209zm0-522c209 0 387 129 459 313-72 184-250 312-459 312S113 684 41 500c72-184 250-313 459-313z"}})])
        )
      }
    }
  