import Vue from 'vue';

export default
{
  state()
  {
    return {
      countries: [],
      labels: null,
      webhotels: null,
      basket: [],
      domainMap: {},
      dnsTypes: [],
      ttlValues: [],
      tldGroups: null,
      unreadTickets: 0,
    };
  },
  getters:
    {
      getCountries(state)
      {
        return state.countries;
      },
      getCountryMap(state)
      {
        const result = {};
        state.countries.forEach(item =>
        {
          result[item.ISO2] = item;
        });
        return result;
      },
      getLabels(state)
      {
        return state.labels;
      },
      getWebhotels(state)
      {
        return state.webhotels;
      },
      getBasket(state)
      {
        return state.basket;
      },
      getDnsTypes(state)
      {
        return state.dnsTypes;
      },
      getTtlValues(state)
      {
        return state.ttlValues;
      },
      getDomainMap(state)
      {
        return state.domainMap;
      },
      tldGroups(state)
      {
        return state.tldGroups;
      },
      tldMap(state)
      {
        const result = {};
        const groups = state.tldGroups || {};
        for (const group in groups)
        {
          for (const tld in groups[group]) result[tld] = groups[group][tld];
        }
        return result;
      },
      getCountUnread(state)
      {
        return state.unreadTickets;
      },
      getInsideBasket(state)
      {
        const result = {};
        const items = (state.basket || {}).item || {};
        for (const id in items)
        {
          const action = items[id].itemaction;
          if (action === 'create-domain-registration' || action === 'create-domain-transfer') result[items[id].item[0]] = id;
        }
        return result;
      },
    },
  mutations:
    {
      setCountries(state, data)
      {
        state.countries = data;
      },
      setLabels(state, data)
      {
        state.labels = data;
      },
      appendLabel(state, label)
      {
        if (!state.labels) state.labels = {};
        Vue.set(state.labels, label.id, label);
      },
      setWebhotels(state, data)
      {
        state.webhotels = data;
      },
      setDomainItem(state, data)
      {
        data.value.id = data.id;
        Vue.set(state.domainMap, data.id, data.value);
      },
      setDomainProp(state, data)
      {
        if (state.domainMap[data.id]) Vue.set(state.domainMap[data.id], data.prop, data.value);
      },
      setDomainLabels(state, data)
      {
        Vue.set(state.domainMap[data.id], 'labels', data.value);
      },
      setDnsTypes(state, data)
      {
        state.dnsTypes = data;
      },
      setTtlValues(state, data)
      {
        state.ttlValues = data;
      },
      setBasket(state, data)
      {
        state.basket = data;
      },
      addToBasket(state, { reqid, data })
      {
        if (!state.basket.item) Vue.set(state.basket, 'item', {});
        Vue.set(state.basket.item, reqid, data);
        if (!state.basket.items) Vue.set(state.basket, 'items', 1);
        else state.basket.items = state.basket.items + 1;
      },
      removeFromBasket(state, reqid)
      {
        if (state.basket.item && state.basket.item[reqid])
        {
          Vue.delete(state.basket.item, reqid);
          state.basket.items = state.basket.items - 1;
        }
      },
      setTLDgroups(state, groups)
      {
        for (const key in groups)
        {
          // backend API represents empty groups as Array instead of Object
          if (Object.toType(groups[key]) == 'array') groups[key] = {};
        }
        state.tldGroups = groups;
      },
      addTldGroup(state, payload)
      {
        state.tldGroups[payload.name] = payload.info;
      },
      setCountUnread(state, count)
      {
        state.unreadTickets = count || 0;
      }
    },
};
