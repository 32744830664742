
    module.exports = {
      functional: true,
      render(_h, _vm) {
        const { _c, _v, data, children = [] } = _vm;

        const {
          class: classNames,
          staticClass,
          style,
          staticStyle,
          attrs = {},
          ...rest
        } = data;

        return _c(
          'svg',
          {
            class: [classNames,staticClass],
            style: [style,staticStyle],
            attrs: Object.assign({"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 175 612 612"}, attrs),
            ...rest,
          },
          children.concat([_c('path',{attrs:{"d":"M306.408 175c55.216 0 106.386 13.79 153.51 41.371s84.252 64.671 111.385 111.272C598.435 374.244 612 425.363 612 481s-13.565 106.756-40.697 153.356c-27.133 46.601-64.261 83.692-111.385 111.273C412.794 773.21 361.624 787 306.408 787s-106.625-13.79-154.224-41.37c-47.601-27.581-84.491-64.673-110.671-111.273C15.333 587.756 1.529 536.636.101 481c-1.429-55.637 12.375-106.755 41.412-153.357 29.036-46.601 65.926-83.692 110.671-111.272C196.927 188.79 248.336 175 306.408 175zm50.694 496.447v-75.608c0-3.804-1.19-6.896-3.57-9.272-2.38-2.378-5.235-3.566-8.567-3.566h-76.398c-3.809 0-6.902 1.188-9.283 3.566-2.38 2.377-3.808 5.469-4.284 9.272v75.608c0 3.804 1.429 6.896 4.284 9.272 2.856 2.378 5.951 3.804 9.283 4.28h76.398c3.332 0 6.188-1.427 8.567-4.28 2.379-2.852 3.57-5.943 3.57-9.272zm-.714-136.951 7.14-246.796c0-3.33-1.428-5.707-4.284-7.133-2.38-2.377-5.474-3.567-9.281-3.567H262.14c-3.808 0-6.902 1.189-9.282 3.567-2.856 1.426-4.284 3.803-4.284 7.133l7.14 246.796c0 2.854 1.189 5.23 3.569 7.133 2.38 1.902 5.712 2.854 9.997 2.854h73.542c3.809 0 6.902-.951 9.282-2.854s3.807-4.28 4.284-7.133z"}})])
        )
      }
    }
  