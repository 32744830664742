<template>
  <footer>
    <span>{{ $root.whiteLabel.globals.brandName || $root.brandInfo.brandName }} {{ copySymbol }} {{ (new Date()).getFullYear() }} &mdash; {{ $t('footer.version') + ' ' + $root.appVersion }}</span>
    <div v-if="!['signUp','affiliate'].includes($route.name) && !$root.mustLogin" :key="refresh" class="system_time">
      {{ $t('footer.system_time') }}: {{ new Date() | stampLocaleLong }} {{ timeZone }}
      <a v-if="$root.whiteLabel.globals.enableLoginLog" class="pl-3 pointer" @click="dlgHistory = true">{{ $t('footer.login_log') }}</a>
    </div>
    <select-language v-if="$root.whiteLabel.globals.enableTranslation || $root.isDEV" />
    <login-history v-model="dlgHistory" />
  </footer>
</template>

<script>
import selectLanguage from '../SelectLanguage';
import loginHistory from './LoginHistory';

export default
{
  name: 'MainFooter',
  components:
    {
      selectLanguage,
      loginHistory,
    },
  data()
  {
    return {
      timer: null,
      refresh: 0,
      dlgHistory: false,
    };
  },
  computed:
    {
      copySymbol()
      {
        return '\xA9';
      },
      timeZone()
      {
        return (new Date()).toTimeString().split(' ')[1];
      }
    },
  created()
  {
    this.timer = setInterval(this.onTimer, 1000);
  },
  beforeDestroy()
  {
    if (this.timer) clearInterval(this.timer);
  },
  methods:
    {
      onTimer()
      {
        // this.refresh++;
      }
    }
};
</script>

<style lang="scss">
  @import '@/assets/scss/theme/footer.scss';

  footer
  {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px 20px;
    background-color: $footer_bg;
    color: $footer_color;
    font-size: 12px;
    font-weight: 300;
  }

  .footer__link
  {
    color: $footer_link;
    text-decoration: none;
  }

  .system_time
  {
    position: fixed;
    bottom: 8px;
    left: 50%;
    transform: translateX(-50%);
  }

  @media screen and (max-width: 1059px)
  {
    .system_time
    {
      transform: none;
      left: auto;
      right: 80px;
    }
  }

  @media screen and (max-width: 750px)
  {
    .system_time
    {
      display: none;
    }
  }
</style>
