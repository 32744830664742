import events from './events';

export default function asyncImport(dynImportFunc)
{
  return async () =>
  {
    if (window.isp) window.isp.showSpinner(true);
    let result;
    try
    {
      result = await dynImportFunc();
      if (window.isp) window.isp.showSpinner(false);
      return result;
    }
    catch (error)
    {
      if (window.isp) window.isp.showSpinner(false);
      // TODO -- think about how to distinguish between network connectivity errors and 404 (caused by deployment of a new version)
      events.$emit('show-failed', error);
      /*
      setTimeout(() =>
      {
        window.location.href = window.location.origin + '/';
      }, 1000);
      */
      throw error;
    }
  };
}
