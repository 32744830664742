<template>
  <div class="badge">
    <slot />
  </div>
</template>

<script>
export default
{
  name: 'BadgeItem',
};
</script>

<style lang="scss">
  .badge
  {
    padding: 2px 6px;
    border-radius: 32px;
    background-color: var(--badge_bg, $badge_background);
    border: 1px solid var(--badge_color, $badge_color);
    color: var(--badge_color, $badge_color);
    font-size: 0.85rem;
  }
</style>
