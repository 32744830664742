
    module.exports = {
      functional: true,
      render(_h, _vm) {
        const { _c, _v, data, children = [] } = _vm;

        const {
          class: classNames,
          staticClass,
          style,
          staticStyle,
          attrs = {},
          ...rest
        } = data;

        return _c(
          'svg',
          {
            class: [classNames,staticClass],
            style: [style,staticStyle],
            attrs: Object.assign({"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 1000 1000"}, attrs),
            ...rest,
          },
          children.concat([_c('path',{attrs:{"d":"M1000 429v142l-185 26q-10 27-21 50l120 157-102 100-159-118q-20 10-51 21l-30 194H429l-27-197q-21-7-43-18L198 905 97 805l121-160q-11-21-19-46L0 571V429l199-28q7-21 19-45L98 197 199 97l160 119q15-8 47-20L434 1h143l28 195q22 7 48 19L811 98l102 100-119 156q13 27 20 48zm-348 72q0-60-43-102t-103-42-102 42-43 102 43 102 102 42 103-42 43-102z"}})])
        )
      }
    }
  