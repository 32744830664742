
    module.exports = {
      functional: true,
      render(_h, _vm) {
        const { _c, _v, data, children = [] } = _vm;

        const {
          class: classNames,
          staticClass,
          style,
          staticStyle,
          attrs = {},
          ...rest
        } = data;

        return _c(
          'svg',
          {
            class: [classNames,staticClass],
            style: [style,staticStyle],
            attrs: Object.assign({"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 571.4 1000"}, attrs),
            ...rest,
          },
          children.concat([_c('path',{attrs:{"d":"m545 729 19 89q2 7-1 13t-10 8h-3q-2 1-6 2t-9 3-12 3-14 3-16 2-19 3-21 2-21 0q-131 0-228-73T71 588H18q-7 0-13-5t-5-13v-63q0-7 5-12t13-6h37q-1-31 0-58H18q-8 0-13-5t-5-13v-64q0-8 5-13t13-5h55q37-117 135-188t224-72q57 0 108 13 6 2 11 9 4 6 2 13l-24 89q-2 7-8 11t-13 1l-2-1q-3 0-7-1l-10-2-12-2-15-2-16-1-16-1q-71 0-126 36t-84 98h261q9 0 14 7 6 7 4 15l-13 63q-3 15-18 15H195q-1 20 0 58h257q8 0 13 7t4 15l-14 63q-1 6-6 10t-11 4H222q27 65 84 104t127 38q10 0 20-1t19-2 16-2 14-3 10-3l7-1 3-2q7-2 14 2 7 3 9 11z"}})])
        )
      }
    }
  