
    module.exports = {
      functional: true,
      render(_h, _vm) {
        const { _c, _v, data, children = [] } = _vm;

        const {
          class: classNames,
          staticClass,
          style,
          staticStyle,
          attrs = {},
          ...rest
        } = data;

        return _c(
          'svg',
          {
            class: [classNames,staticClass],
            style: [style,staticStyle],
            attrs: Object.assign({"xmlns":"http://www.w3.org/2000/svg","viewBox":"-21.5 -2.5 664 664"}, attrs),
            ...rest,
          },
          children.concat([_c('path',{attrs:{"fill":"none","stroke":"currentColor","stroke-width":"150","stroke-linecap":"round","stroke-linejoin":"round","d":"M60.61 371.814 211.86 520.04l348.48-349.085"}})])
        )
      }
    }
  