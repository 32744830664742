<template>
  <router-link v-if="$root.whiteLabel.globals.enableBasket" class="pa-2 default_button dark" :to="{name: 'checkout'}">
    <div class="flexbox justify-center relative pr-4">
      <my-icon :size="24" :style="basketCount ? '' : {opacity: 0.7}">
        <icon-basket />
      </my-icon>
      <badge v-if="basketCount > 0" class="basket_badge absolute">
        {{ basketCount }}
      </badge>
    </div>
  </router-link>
</template>

<script>
import { mapGetters } from 'vuex';
import badge from '@/components/ui/BadgeItem';
import iconBasket from '@/assets/img/icon/shopping-cart-solid.svg';

export default
{
  name: 'HeaderBasket',
  components:
    {
      badge,
      iconBasket,
    },
  computed:
    {
      ...mapGetters(['getBasket']),
      basketCount()
      {
        return Object.keys((this.getBasket || {}).item || {}).length;
      }
    }
};
</script>

<style>
  .badge.basket_badge
  {
    background-color: #444;
    color: white;
    padding-left: 6px;
    padding-right: 6px;
    top: -8px;
    left: 20px;
  }
</style>
