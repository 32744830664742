
    module.exports = {
      functional: true,
      render(_h, _vm) {
        const { _c, _v, data, children = [] } = _vm;

        const {
          class: classNames,
          staticClass,
          style,
          staticStyle,
          attrs = {},
          ...rest
        } = data;

        return _c(
          'svg',
          {
            class: [classNames,staticClass],
            style: [style,staticStyle],
            attrs: Object.assign({"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 857.1 1000"}, attrs),
            ...rest,
          },
          children.concat([_c('path',{attrs:{"d":"M571 768v-89q0-8-5-13t-12-5h-54V375q0-8-5-13t-13-5H304q-8 0-13 5t-5 13v89q0 8 5 13t13 5h53v179h-53q-8 0-13 5t-5 13v89q0 8 5 13t13 5h250q7 0 12-5t5-13zm-71-500v-89q0-8-5-13t-13-5H375q-8 0-13 5t-5 13v89q0 8 5 13t13 5h107q8 0 13-5t5-13zm357 232q0 117-57 215T644 871t-215 58-216-58T58 715 0 500t58-215 155-156 216-58 215 58 156 156 57 215z"}})])
        )
      }
    }
  