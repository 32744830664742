
    module.exports = {
      functional: true,
      render(_h, _vm) {
        const { _c, _v, data, children = [] } = _vm;

        const {
          class: classNames,
          staticClass,
          style,
          staticStyle,
          attrs = {},
          ...rest
        } = data;

        return _c(
          'svg',
          {
            class: [classNames,staticClass],
            style: [style,staticStyle],
            attrs: Object.assign({"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 857.1 1000"}, attrs),
            ...rest,
          },
          children.concat([_c('path',{attrs:{"d":"M819 212q16 16 27 42t11 50v642q0 23-15 38t-38 16H54q-23 0-38-16T0 946V54q0-23 16-38T54 0h500q22 0 49 11t42 27zM571 76v210h210q-5-17-12-23L594 88q-6-7-23-12zm215 853V357H554q-23 0-38-16t-16-37V71H71v858h715zM214 446q0-7 5-12t13-5h393q8 0 13 5t5 12v36q0 8-5 13t-13 5H232q-8 0-13-5t-5-13v-36zm411 125q8 0 13 5t5 13v36q0 8-5 13t-13 5H232q-8 0-13-5t-5-13v-36q0-8 5-13t13-5h393zm0 143q8 0 13 5t5 13v36q0 8-5 13t-13 5H232q-8 0-13-5t-5-13v-36q0-8 5-13t13-5h393z"}})])
        )
      }
    }
  