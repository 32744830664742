<template>
  <form v-bind="$attrs" v-on="listeners">
    <slot />
  </form>
</template>

<script>
export default
{
  name: 'FormValidator',
  provide()
  {
    return {
      form: this
    };
  },
  inheritAttrs: false,
  data()
  {
    return {
      dirty: false,
      fieldList: [],
    };
  },
  computed:
    {
      listeners()
      {
        return {
          ...this.$listeners,
          submit: (evt) =>
          {
            evt.preventDefault();
            this.fieldList.forEach(item =>
            {
              item.setDirty(true);
            });
            this.$nextTick(() =>
            {
              this.$emit('submit', evt);
            });
          }
        };
      }
    },
  methods:
    {
      register(field)
      {
        this.fieldList.push(field);
      },
      unregister(field)
      {
        const idx = this.fieldList.indexOf(field);
        if (idx !== -1) this.fieldList.splice(idx, 1);
      },
      fields()
      {
        // we can not use computed property because $children is not reactive
        const child = this.$children.slice();
        const fields = [];
        let cur;
        while (child.length > 0)
        {
          cur = child.shift();
          if (cur.$options.name === 'FormField') fields.push(cur);
          else
          {
            cur = cur.$children.slice();
            for (let i = 0; i < cur.length; i++) child.push(cur[i]);
          }
        }
        return fields;
      },
      valid()
      {
        const fields = this.fieldList; // fields();
        return fields.every(item => item.valid /*item.$options.propsData.error && item.dirty*/);
      },
      reset()
      {
        this.fieldList.forEach(item => item.setDirty(false));
      },
      firstError()
      {
        const fields = this.fieldList; // fields();
        return (fields.find(item => !item.valid) || {}).error;
      }
    }
};
</script>
