import Vue from 'vue';
import Vuex from 'vuex';
import persistedState from 'vuex-persistedstate';
import preferences from './modules/preferences';
import cache from './modules/cache';

Vue.use(Vuex);

export default new Vuex.Store(
  {
    plugins:
      [
        persistedState(
          {
            key: 'namesrs',
            paths: ['preferences']
          }
        )
      ],
    modules:
      {
        preferences,
        cache,
      },
    strict: process.env.NODE_ENV !== 'production'
  });
