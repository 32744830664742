<template>
  <transition :name="transition">
    <div v-if="value" class="modal_overlay" :class="{light: light}" @mousedown.self="closeDialog(false)">
      <div class="modal_content" :class="contentClass" :style="contentStyle" @click.stop>
        <slot />
      </div>
    </div>
  </transition>
</template>

<script>
export default
{
  name: 'MyModal',
  props:
    {
      value:
        {
          type: Boolean,
          default: false
        },
      transition:
        {
          type: String,
          default: 'modal'
        },
      persistent:
        {
          // if True - dialog will not close when clicking on the overlay
          type: Boolean,
          default: false
        },
      escape:
        {
          // whether to close the dialog with ESC key
          type: Boolean,
          default: true // eslint-disable-line vue/no-boolean-default
        },
      light:
        {
          // apply lighter modal overlay
          type: Boolean,
          default: false
        },
      contentClass:
        {
          type: [String, Object, Array],
          default: ''
        },
      contentStyle:
        {
          type: [String, Object, Array],
          default: ''
        }
    },
  created()
  {
    if (this.escape) document.addEventListener('keydown', this.modalEscape, false);
  },
  beforeDestroy()
  {
    if (this.escape) document.removeEventListener('keydown', this.modalEscape, false);
  },
  methods:
    {
      closeDialog(inside)
      {
        if (this.persistent ? inside : true)
        {
          this.$emit('input', false);
          if (!inside) this.$emit('outside');
        }
      },
      modalEscape(event)
      {
        const e = event || window.event;
        if (this.value && e.keyCode === 27)
        {
          if (this.escape) this.closeDialog(true);
          // All good browsers…
          if (e.preventDefault) e.preventDefault();
          // …and IE
          if (e.returnValue) e.returnValue = false;
          return false;
        }
        return true;
      }
    }
};
</script>

<style>
  .modal_overlay
  {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 5;
    text-align: initial;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
  }

  .modal_overlay.light
  {
    background-color: rgba(0, 0, 0, 0.1);
  }

  .modal_content
  {
    margin: auto;
    display: inline-block;
    max-width: 90vw;
    max-height: 85vh;
    overflow: hidden;
  }

</style>
