<template>
  <div class="flexbox flex-wrap justify-center">
    <template v-if="pageCount > 1">
      <button v-for="page in pagesLeft" :key="page" :class="{page_item: true, current: value === page}" @click="setPage(page)">{{ page > 0 ? page : '...' }}</button>
      <button class="page_item current">{{ value }}</button>
      <button v-for="page in pagesRight" :key="page" :class="{page_item: true, current: value === page}" @click="setPage(page)">{{ page > 0 ? page : '...' }}</button>
    </template>
    <div v-else />
  </div>
</template>

<script>
export default
{
  name: 'PaginationList',
  props:
    {
      pageSize:
      {
        // number of items per page
        type: Number,
        default: 20
      },
      count:
      {
        // total number of items
        type: Number,
        default: 0
      },
      value:
      {
        // current page
        type: Number,
        default: 1
      }
    },
  computed:
  {
    pageCount()
    {
      return Math.ceil(this.count / this.pageSize);
    },
    numLeft()
    {
      return this.value > 1 ? this.value - 1 : 0;
    },
    numRight()
    {
      return this.value < this.pageCount ? this.pageCount - this.value : 0;
    },
    pagesLeft()
    {
      switch (this.numLeft)
      {
        case 0:
          return [];
        case 1:
          return [1];
        case 2:
          return [1, 2];
        case 3:
          return [1, 2, 3];
        case 4:
          return [1, 2, 3, 4];
        default:
          return [1,
            2,
            -1,
            this.numLeft - 1,
            this.numLeft];
      }
    },
    pagesRight()
    {
      switch (this.numRight)
      {
        case 0:
          return [];
        case 1:
          return [this.pageCount];
        case 2:
          return [this.pageCount - 1, this.pageCount];
        case 3:
          return [this.pageCount - 2, this.pageCount - 1, this.pageCount];
        case 4:
          return [this.pageCount - 3, this.pageCount - 2, this.pageCount - 1, this.pageCount];
        default:
          return [this.pageCount - this.numRight + 1,
            this.pageCount - this.numRight + 2,
            -2,
            this.pageCount - 1,
            this.pageCount];
      }
    },
  },
  methods:
  {
    setPage(page)
    {
      if (page === -1) page = Math.floor(1 + this.numLeft / 2);
      else if (page === -2) page = Math.ceil(this.value + this.numRight / 2);
      this.$emit('input', page);
      this.$emit('change', page);
    }
  }
};
</script>

<style lang="scss">
  .page_item
  {
    -webkit-appearance: none;
    border-radius: 4px;
    border: 1px solid $dialog_border;
    padding: 8px 6px 6px;
    margin: 4px 6px;
    font-size: 0.95rem;
    min-width: 2rem;
    font-weight: normal;
    line-height: 1;
    cursor: pointer;
    background-color: transparent;
    transition: background-color 0.3s ease, color 0.1s ease, border-color 0.3s ease;
  }

  .page_item.current
  {
    background-color: var(--page_current_bg, $light_bg);
    color: var(--page_current_color, $primary_color);
    cursor: default;
  }

  .page_item:not(.current):hover
  {
    background-color: var(--page_hover_bg, #CCC);
    color: var(--page_hover_color, #000);
  }
</style>
