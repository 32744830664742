import AsyncFactory from '../async';

export default
[
  {
    path: 'invoices',
    name: 'invoices',
    component: AsyncFactory(() => import(/* webpackChunkName: "Economy" */ '@/views/economy/InvoiceList')),
    meta:
      {
        title: 'economy.invoices.title',
        localize: true,
        menu: (root) => !!(((root.whiteLabel || {}).globals || {}).menuEconomy || {}).showInvoices,
        kind: 'A',
        query:
          {
            desc: 'true',
            orderby: 'created',
            page: 1,
          }
      },
    beforeEnter: (to, from, next) =>
    {
      if (!window.isp) next();
      else if (window.isp.whiteLabel.globals.showMenuEconomy) next();
      else
      {
        const parent = window.isp.menu('economy');
        const children = (parent.children || []).filter(item => (typeof item.meta.menu === 'function' ? item.meta.menu(window.isp) : item.meta.menu));
        next(children[0] || false);
      }
    },
  },
  {
    path: 'receipts',
    name: 'receipts',
    component: AsyncFactory(() => import(/* webpackChunkName: "Economy" */ '@/views/economy/InvoiceList')),
    meta:
      {
        title: 'economy.receipts.title',
        localize: true,
        menu: (root) => !!(((root.whiteLabel || {}).globals || {}).menuEconomy || {}).showReceipts,
        kind: 'K'
      },
    beforeEnter: (to, from, next) =>
    {
      if (!window.isp) next();
      else if (window.isp.whiteLabel.globals.showMenuEconomy) next();
      else
      {
        const parent = window.isp.menu('economy');
        const children = (parent.children || []).filter(item => (typeof item.meta.menu === 'function' ? item.meta.menu(window.isp) : item.meta.menu));
        next(children[0] || false);
      }
    },
  },
  {
    path: 'deposit',
    name: 'deposit',
    component: AsyncFactory(() => import(/* webpackChunkName: "Economy" */ '@/views/economy/MakeDeposit')),
    meta:
      {
        title: 'economy.deposit.title',
        localize: true,
        menu: (root) => !!(((root.whiteLabel || {}).globals || {}).menuEconomy || {}).showDeposit,
      },
    beforeEnter: (to, from, next) =>
    {
      if (!window.isp) next();
      else if (window.isp.whiteLabel.globals.showMenuEconomy) next();
      else
      {
        const parent = window.isp.menu('economy');
        const children = (parent.children || []).filter(item => (typeof item.meta.menu === 'function' ? item.meta.menu(window.isp) : item.meta.menu));
        next(children[0] || false);
      }
    },
  },
  {
    path: 'pricelist',
    name: 'priceList',
    component: AsyncFactory(() => import(/* webpackChunkName: "Economy" */ '@/views/economy/PriceList')),
    meta:
      {
        title: 'economy.pricelist.title',
        localize: true,
        menu: (root) => !!(((root.whiteLabel || {}).globals || {}).menuEconomy || {}).showPricelist,
      },
    beforeEnter: (to, from, next) =>
    {
      if (!window.isp) next();
      else if (window.isp.whiteLabel.globals.showMenuEconomy) next();
      else
      {
        const parent = window.isp.menu('economy');
        const children = (parent.children || []).filter(item => (typeof item.meta.menu === 'function' ? item.meta.menu(window.isp) : item.meta.menu));
        next(children[0] || false);
      }
    },
  },
];
