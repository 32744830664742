
    module.exports = {
      functional: true,
      render(_h, _vm) {
        const { _c, _v, data, children = [] } = _vm;

        const {
          class: classNames,
          staticClass,
          style,
          staticStyle,
          attrs = {},
          ...rest
        } = data;

        return _c(
          'svg',
          {
            class: ["folder-open-solid_svg__svg-inline--fa folder-open-solid_svg__fa-folder-open folder-open-solid_svg__fa-w-18",classNames,staticClass],
            style: [style,staticStyle],
            attrs: Object.assign({"aria-hidden":"true","data-prefix":"fas","data-icon":"folder-open","xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 576 512"}, attrs),
            ...rest,
          },
          children.concat([_c('path',{attrs:{"fill":"currentColor","d":"M572.694 292.093 500.27 416.248A63.997 63.997 0 0 1 444.989 448H45.025c-18.523 0-30.064-20.093-20.731-36.093l72.424-124.155A64 64 0 0 1 152 256h399.964c18.523 0 30.064 20.093 20.73 36.093zM152 224h328v-48c0-26.51-21.49-48-48-48H272l-64-64H48C21.49 64 0 85.49 0 112v278.046l69.077-118.418C86.214 242.25 117.989 224 152 224z"}})])
        )
      }
    }
  