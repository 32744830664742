<template>
  <normal-button v-bind="$attrs" class="color_button" :style="btnColor" v-on="$listeners">
    <slot />
  </normal-button>
</template>

<script>
import normalButton from './NormalButton';

export default
{
  name: 'ColorButton',
  components:
    {
      normalButton,
    },
  inheritAttrs: false,
  props:
    {
      color:
        {
          type: String,
          default: ''
        },
      background:
        {
          type: String,
          default: ''
        }
    },
  computed:
    {
      btnColor()
      {
        const result = {};
        if (this.background) result['background-color'] = this.background;
        if (this.color) result.color = this.color;
        return result;
      }
    }
};
</script>

<style lang="scss">
  .color_button.info
  {
    background-color: $button_info_background !important;
    border: 1px solid $button_info_border !important;
    color: $button_info_color;
  }

  .color_button.info:not(:disabled):hover
  {
    background-color: $button_info_border !important;
  }

  .color_button.error
  {
    background-color: $button_error_background !important;
    border: 1px solid $button_error_border !important;
    color: $button_error_color;
  }

  .color_button.error:not(:disabled):hover
  {
    background-color: $button_error_border !important;
  }

  .color_button.success
  {
    background-color: $button_success_background !important;
    border: 1px solid $button_success_border !important;
    color: $button_success_color;
  }

  .color_button.success:not(:disabled):hover
  {
    background-color: $button_success_border !important;
  }

  .color_button.warn
  {
    background-color: $button_warning_background !important;
    border: 1px solid $button_warning_border !important;
    color: $button_warning_color;
  }

  .color_button.warn:not(:disabled):hover
  {
    background-color: $button_warning_border !important;
  }

  .color_button.default_button.dark
  {
    /* color: black; */
  }

  /* must be last */
  .color_button.default_button:disabled
  {
    border-color: transparent;
  }

</style>
